import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
  return (

    <footer className="footer">
     <div className="footer-top">
    <div className="container">
      <div className="mrg-left">
        <div className="row">
          <div className="col-lg-3 col-md-12 offset-md-1 col-sm-12 md-mb-10 pl-55 md-pl-15 animate__animated animate__fadeInUp ">
            <h3 className="footer-title">COMPANY</h3>
            <ul className="site-map">
              <li><Link to="/Aboutus">About Us</Link></li>
              <li><Link to="/Career">Career</Link></li>
              <li><Link to="/Media">Media</Link></li>
              <li><Link to="/Contactus">Contact Us</Link></li>
              <li><Link to="/Termsofuse">Terms of use</Link></li>
              <li><Link to="/PrivacyPolicy">Privacy Policy</Link></li>
             
            </ul>
          </div>
          <div className="col-lg-4 col-md-12 col-sm-12 md-mb-10 animate__animated animate__fadeInUp ">
            <h3 className="footer-title">Our Offices</h3>
            <ul className="address-widget">
              <li>
                <div className="location_name">Noida</div>
                <i className="flaticon-location" />
                <div className="desc">
                  11th Floor, Gulshan One29, <br />Sec-129 Noida,<br /> Uttar Pradesh- 201304</div>
              </li>
              <li>
                <div className="location_name"> Gurugram</div>
                <i className="flaticon-location" />
                <div className="desc">
                  10C, Tower-B3, <br />Spaze IT Park,<br />Sector-49, Gurugram-122018</div>
              </li>
            </ul>
          </div>
          <div className="col-lg-4 col-md-12 col-sm-12 md-mb-10 animate__animated animate__fadeInUp ">
            <h3 className="footer-title">Reach us</h3>
            <ul className="address-widget">
              <li>
                <i className="flaticon-call" />
                <div className="desc">
                  <Link to="tel:0120-5109230">(+91) 120-5109230</Link>
                </div>
              </li>
              <li>
                <i className="flaticon-email" />
                <div className="desc">
                  <Link to="mailto:info@blackopalgroup.in"> info@blackopalgroup.in</Link>
                </div>
              </li>
            </ul>
            <div className="follow md-mb-30">
              <h3 className="footer-title">Follow us</h3>
              <ul className="footer-social md-mb-30">
                <li>
                  <Link to="https://www.facebook.com/Blackopal-Group-105304038115767/?ref=pages_you_manage" target="_blank"><span><i className="fa fa-facebook" /></span></Link>
                </li>
                <li>
                  <Link to="https://www.linkedin.com/company/blackopal-group/" target="_blank"><span><i className="fa fa-linkedin" /></span></Link>
                </li>
                <li>
                  <Link to="https://www.instagram.com/blackopalgroup/?igshid=YmMyMTA2M2Y=" target="_blank"><span><i className="fa fa-instagram" /></span></Link>
                </li>
                <li>
                  <Link to="https://www.youtube.com/channel/UCQAni1Yzdw2FcYBItc7tc8g" target="_blank"><span><i className="fa fa-youtube" /></span></Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="line" />
  <div className="footer-bottom">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="copyright text-lg-start ">
            <p style={{textAlign: 'center !important'}}>@ Copyright. Blackopal Group 2023, All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>



  )
}
