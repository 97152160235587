import React from 'react'
import Header from '../components/Header';
import Footer from "../components/Footer";
import { Helmet } from 'react-helmet';

 const PrivacyPolicy = () => {
  const pageTitle = "Privacy Policy";
  return (
   

    <div className="main-content">
  <Helmet>
    <title>{pageTitle}</title>
   
  </Helmet> 
    <Header/>
   <div className="container">
  <div className="row">
    <div className="col-md-12 col-md-6 wow animate__animated animate__fadeInUp">
      <div className="about-section">
        <h2>Privacy Policy</h2>
        <p>Black Opal Group, its subsidiaries and branch operations hereinafter referred to as 'Black Opal Group,' 'we,' 'us,' or 'our,' are committed to respecting your privacy and choices. This privacy policy (hereinafter "Privacy Policy" or "Policy") highlights our privacy practices regarding Personal Information that we collect and process in compliance with applicable data privacy regulations.
        </p>
        <h3>Objective:
        </h3>
        <p>Through this Privacy Policy, we intend to provide a synopsis of our privacy practices regarding the Personal Information we collect and process about you through various sources. Although the primary focus of this Policy is on the data collected and processed through our websites that link to this Privacy Policy, which include www.blackopalgroup.in  and their sub-domains (Collectively referred to as "Black Opal Group Websites"), our adherence to the below-mentioned principles remains across the organization towards personal data processing. Depending on the purposes of processing and your relationship with us as a data subject, we may provide additional customized privacy policies, more information on which can be found below.
        </p>
        <p> The scope of this Policy covers the categories of personal data collected, how we use or process such data, who the recipients of such data are, and your associated rights under applicable laws, including how to exercise the same. The applicability of the Privacy Policy is global. However, certain additional information may be relevant to you depending on the country where you reside. Such other terms, based on these particular countries or regions, are called out in region-specific Policys below.
        </p>
        <p>Wherever we may have provided the translation of our Policy in local languages, as per applicability, in case of a conflict between the local language and the English version, the English version shall prevail.
        </p>
        <h3>1. Personal Information that we process and use</h3>
        <span style={{fontSize: 18, marginBottom: 20, fontWeight: 400}}>1.1 Personal Information that we process</span> <br />
        <p>We may collect the following categories of Personal Information: </p>
        <p>Information collected as part of your visit and usage of our websites, such as Internet Protocol (IP) address, demographics, your computer's operating system, device information, telemetry data, and browser type, and information collected via cookies or similar tracking technologies. As a website visitor who merely browses our websites (including the hosted content) without submitting any additional data, this is the most relevant paragraph for applicable data processing.</p> 
        <p>Personal and contact details, such as name, title, email address, phone numbers, address, designation, company/organization, industry, location (in terms of city/country), LinkedIn profiles/URLs (or similar professional data set). </p>
        <p> Login credentials, such as usernames and passwords, when you register for any of our applicable services. </p>
        <p> Audio-visual information, such as photographs or images captured, video recordings (if enabled), when attending any of our events, webinars, etc. </p>
        <p> Queries, comments, or feedback as submitted by you, including any correspondence you have made with us. </p>
        <p> Preferences relating to marketing communications, interest areas, subscription details, dietary preferences/allergies (in relation to events we may organize). </p>
        <p>  Additionally, we may collect certain data points based on the categories of data subjects and their relationship with us, as detailed in additional Privacy Policys for relevant categories of data subjects at the end of this Policy. </p>
        <span style={{fontSize: 18, marginBottom: 20, fontWeight: 400}}>1.2 Sources of Personal Information</span>
        <p>We may collect the aforementioned Personal Information through various sources, such as mentioned below:</p> 
        <p> -Submitted by yourself through our website forms, applications on our portals, or by contacting/emailing our official contacts.</p> 
        <p> -Shared with Black Opal Group employees, such as our sales or marketing representatives.</p> 
        <p> -Shared with or by Black Opal Group's affiliates.</p> 
        <p> -Shared by the employers of the visitors and contractors, where applicable.</p> 
        <p> -Sourced from public websites and social media, including your publicly accessible profiles.</p> 
        <p> -Shared by our suppliers, vendors, and service providers.</p> 
        <p> -Sourced via cookies and similar tracking technologies deployed on our website (details are available in the Cookie Policy)</p> 
        <span style={{fontSize: 18, marginBottom: 20, fontWeight: 400}}>1.3 Use of Your Personal Information</span>
        <p>We may use your Personal Information for the following purposes:</p> 
        <p>- To provide better usability, troubleshooting, and site maintenance on our websites.</p> 
        <p>- To understand which parts of the website are visited and how frequently for analytical purposes.</p> 
        <p>- To create your login credentials, where applicable.</p> 
        <p>- To identify you once you register on our website.</p> 
        <p>- To facilitate communication with you, including contacting you and responding to your queries or requests.</p> 
        <p>- To provide access to desirable content based on your preferences.</p> 
        <p>- To enable marketing and sales-related communications and activities.</p> 
        <p>- To gauge your interest in participating in ideathons or other competitions.</p> 
        <p> - As a participant in any competitions and/or award ceremonies organized by us, publish your name and corresponding details to make them available to a larger audience on the Internet (including details of awards won).</p>
        <p>- To invite you to events, seminars, and equivalent ceremonies organized by us and for related promotional purposes, including promotions or publications on social media.</p>
        <p>- To publish testimonials and case studies.</p>
        <p>- To generate and maintain leads as part of our Customer Relationship Management database.</p>
        <p>- To perform data analytics for providing a better user experience, enhancing website performance, and achieving business objectives.</p>
        <p>- To enhance and optimize our business operations, applications, systems, and processes, including the operation and management of our communication assets and systems, ensuring information security, and maintaining audit trails and associated records.</p>
        <p>- To protect your data and Black Opal Group assets from information security threats and to secure against any unauthorized access, disclosure, alteration, or destruction.</p>
        <p>- To comply with applicable laws and legal requirements, such as regulatory filings, record-keeping and reporting obligations, cooperating with government-authorized inspections or requests, protecting our legal rights, and seeking remedies, including defending against any claims or engaging in legal proceedings, responding to subpoenas or court orders, and managing documentation for related purposes.</p>
        <p>- To enhance your website experience and for other associated purposes by deploying cookies or similar technologies. These may serve different purposes, such as ensuring the seamless performance of the website, enhancing functionalities, understanding usage patterns, and determining the interests of website visitors.</p>
        <span style={{fontSize: 18, marginBottom: 20, fontWeight: 400}}>1.4 Legal basis of the processing
        </span>
        <p>The processing of your Personal Information is based on the following legal bases:
        </p>
        <p> 1.4.1 Performance of a contract: We may process your Personal Information when it is necessary for the performance of a contract to which you are a party or to take steps at your request prior to entering into a contract. For example, when you engage with us for receiving our services and offerings or when managing the data of our employees for ensuring the performance of their employment contract.</p>
        <p>  1.4.2 Legitimate interests: We may process your Personal Information when it is necessary for the purposes of a legitimate interest pursued by us or a third party, provided that these interests are not overridden by your data protection rights. This includes purposes such as understanding your usage of our website, generating secure login credentials, and optimizing our processes.</p>
        <p>  1.4.3 Consent: We may process your Personal Information based on your consent. If we process Personal Data based on consent, you have the right to withdraw your consent at any time. This includes situations such as registering and inviting you to events organized by us or sharing our marketing-related communications with you.</p>
        <p>  1.4.4 Legal obligations: We may process your Personal Information to comply with legal obligations imposed on us, including applicable laws, protecting our legal rights, seeking remedies, and defending against claims.</p>
        <h3>2. Consequences of not providing Personal Information
        </h3>
        <p>If you choose not to provide your Personal Information that is mandatory to process your request, we may not be able to fulfill the relevant purpose of processing, including the provision of any services to you, if applicable.
        </p>
        <h3>3. Data recipients, transfer, and disclosure of Personal Information:
        </h3>
        <span style={{fontSize: 18, marginBottom: 20, fontWeight: 400}}>3.1 Black Opal Group does not share your Personal Information with third parties for their direct marketing purposes.
        </span>
        <span style={{fontSize: 18, marginBottom: 20, fontWeight: 400}}>We share your Personal Information within the:
        </span>
        <p>Black Opal Group or any of its subsidiaries.</p>
        <p> Business partners.</p>
        <p> Service providers.</p>
        <p> Authorized third-party agents.</p>
        <p> Auditors, and/or government authorities, where applicable.</p>
        <span style={{fontSize: 18, marginBottom: 20, fontWeight: 400}}>3.2 Facilitating International Data Transfers:
          <p>We may transfer Personal Information to countries outside of your country of residence, either too Black Opal Group companies or third parties, including countries that have different data protection standards from those that apply in your country or region of residence. The locations of Black Opal Group companies are set out here. Our service providers are located in India only.
          </p><p>When transferring data outside your country or region of residence, we will take reasonable and appropriate steps in line with applicable laws to ensure the security and privacy of your Personal Information. For additional details on international transfers of data, please refer to our region-specific Privacy Policy.
          </p>
          <p>  Please contact us as set out below if you would like to obtain a copy of the methods used when executing such international transfers, as required by applicable laws. When necessary, Black Opal Group may disclose Personal Information to external law enforcement bodies or regulatory authorities to comply with legal obligations.
          </p>                
          <h3>4. Your Rights:
          </h3>
          <p> 4.1 Subject to the laws of your country, you may have certain rights as a data subject (including but not limited to the right to information, access, rectification, erasure, objection, restriction of processing, and the right to lodge complaints) regarding your Personal Information that we process. If the data processing is based on your consent, you may withdraw your consent to the processing of any Personal Data at any time. This will not affect the lawfulness of any processing operation before your withdrawal. </p>
          <p>  Your rights as a data subject may vary depending on your applicable jurisdiction. </p>
          <p> 4.2 Exercising your rights: To exercise your rights, we may need to request certain information from you to verify your identity and ensure that you are entitled to exercise those rights. You can exercise your rights by contacting us at info@blackopalgroup.in.  </p>
          <p> 4.3 Preference Center: You can choose to update your Black Opal Group marketing communications preferences through our Contact Preference Center. </p>
          <p />
          <h3>5. Data security
          </h3>
          <p>At Black Opal Group, we prioritize the governance, process, and technology necessary to establish our commitment to the security of our customers and stakeholders' information. We adopt reasonable and appropriate security controls, practices, and procedures, including administrative, physical security, and technical controls, to safeguard your Personal Information.
          </p>
          <h3>6. Data retention
          </h3>
          <p>
            Personal Information will not be retained for a period longer than necessary to fulfill the purposes outlined in this privacy Policy, unless a longer retention period is required by law or for directly related legitimate business purposes. Personal Data that is no longer required to be retained as per legal and business requirements will be securely disposed of.
          </p>
          <h3>7. Contact your Local Data Protection Authority
          </h3>
          <p>If you are dissatisfied with how we safeguard your personal data, depending on the laws of the countries where you reside, you have the right to lodge a complaint with your local data protection authority.
          </p>
          <h3>8. Linked websites
          </h3>
          <p>Our privacy practices regarding Personal Information collected and stored about you through our portals, such as Recruitment and Global Alumni, will be in accordance with the privacy policy of those portals.
            Black Opal Group provides links to third-party websites and services. However, Black Opal Group is not responsible for the privacy Policys, practices, or contents of such third-party websites.
          </p>
          <h3>9. Children's Privacy
          </h3>
          <p>Black Opal Group websites, associated products or services, and hosted content are not intended for use by children.  </p>
          <p> Therefore, we do not knowingly solicit or collect personally identifiable information online from children without prior verifiable parental consent. If Black Opal Group learns that a child has submitted personally identifiable information online without parental consent, we will take all reasonable measures to delete such information from our databases and not use it for any purpose (except where necessary to protect the safety of the child or others as required or allowed by law). If you become aware of any personally identifiable information we have collected from children, please email us at <a mailto="info@blackopalgroup.in">info@blackopalgroup.in</a></p>
          <h3>10. How to contact us
          </h3>
          <p>If you have any questions regarding our privacy practices or this Privacy Policy, or if you would like to request this Privacy Policy in another format, please contact us at:
          </p>
          <span>Contact person: Ram Bhushan Maurya</span><br />
          <span>Contact address: Black Opal Group, 11th floor, Gulshan One29, Sector-129, Noida, India</span><br />
          <span>Phone: +91 - 1205109230</span><br />
          <span>Email:<a href="info@blackopalgroup.in"> info@blackopalgroup.in</a> </span><br /><br />
          <h3>11. Updates to this Privacy Policy</h3>
          <p>Black Opal Group may change its data privacy practices and update this Privacy Policy as needed. The updated version will be made available on the website. However, our commitment to protecting the privacy of website users will remain unchanged.</p>
        </span></div>
    </div>
  </div>
</div>
<Footer/>
</div>


  )
}

export default PrivacyPolicy;
