import React from 'react';
import Header from '../components/Header';
import Footer from "../components/Footer";
import { Helmet } from 'react-helmet';

export const Aboutus = () => {
  const pageTitle = "About Us";

  return (
     


    <div>
     <Helmet>
        <title>{pageTitle}</title>
        
      </Helmet> 

       <div class="main-content">
       <Header/>
  <section className="banner">
    <div className="container">
      <div className="about-text2">
      </div>
    </div>
  </section>
  <div className="container">
    <div className="row">
      <div className="col-md-12 col-md-6 wow animate__animated animate__fadeInUp">
        <div className="about-section">
          <h2>Company With a Purpose</h2>
          <p>Embark on an exciting real estate journey with us! Our mission is simple: Customer Empowerment. We achieve this through smart tools, perfect match-making, and customized credit solutions. Bid farewell to the buying confusion.
          </p>
          <p>To bring excellence to our mission, we attract, nurture, and retain the best talent. Our organization thrives on creativity and ideas, guided solely by meritocracy. From a mere idea on the drawing board, we have grown into a team of over 50+ ambitious professionals who share the dream of helping customers find their dream homes every single day. </p>
          <p> Our ultimate goal? To revolutionize the real estate experience worldwide. We're not satisfied with the status quo. Instead, we aim to redefine how we make buying decisions. Together, let's usher in a new era, transforming the way we experience real estate and unlocking endless possibilities.
          </p>
        </div>
      </div>
    </div>
  </div>
  <section className="westand-section">
    <div className="container">
      <div className="row">
        <div className="content-column col-md-6 wow animate__animated animate__fadeInLeft " style={{animationName: 'fadeInLeft'}}>
          <div className="wedo-cont">
            <h3>What we stand for
            </h3>
            <img src="assets/img/empowering.png" alt='Empowering Customers' className="about-icon" />
            <p>
              <b>Empowering Customers:</b> We place the power of verified information in the hands of our customers. Discover your dream home and embark on a personalized real estate experience tailored to your needs with our analytical, curated, and verified matchmaking framework. With us, you can find your dream home with just a swipe.<br />
            </p>
            <div className="about-icon"><img src="assets/img/tech.png" alt='Unleashing Capital potential' /> </div>
            <p>
              <b>Unleashing Capital potential: </b> Access to capital is a crucial factor throughout the real estate transaction lifecycle. Offering swift and tech enabled capital access, tailored to individual needs, can have a profound impact on the socio-economic landscape of our country.
            </p>
            <img src="assets/img/real-estate-growth.png" alt='Nurturing Growth Partner Ecosystem' className="about-icon" />
            <p>
              <b>Nurturing Growth Partner Ecosystem: </b> Our aim is to ignite the entrepreneurial spirit of the growth partners to enhance integrity, transparency and professionalism across the value chain. Ensuring verified growth partner network while supporting their business with curated financial offerings, can enhance their contribution to the value chain significantly.
            </p>
          </div>
        </div>
        <div className=" image-column col-md-6 wow animate__animated animate__fadeInRight " style={{animationName: 'fadeInRight'}}>
          <div className="wedo-img">
            <img src="assets/img/what-we-do.jpg" alt='what we do' className="borderbottom" />
          </div>
        </div>
      </div>
    </div>
  </section>
  <div className="bo-counter style1">
    <div className="container">
      <div className="counter-border-top">
        <h2>Our Journey so far</h2>
        {/* <p class="bo mr50">We take pride in everything we do, and our growth stems from our growing customer base whose
              lives have been positively impacted through our products. All of which is reflected through
              our numbers.</p> */}
      </div>
      <div className="row">
        <div className="col-lg-3 col-md-6 col-sm-6 md-mb-30 wow animate__animated animate__fadeInUp " style={{animationDelay: '0.11s', animationName: 'fadeInUp'}}>
          <div className="counter-area">
            <div className="counter-list mb-20">
              <div className="counter-icon">
                <img src="assets/img/Reviews.png" alt="Our Journey so far" />
                <div className="counter-number">
                  <span className="bo-count"> 50 </span>
                  <span style={{fontSize: 30, color: 'var(--brand-colour)'}}> +</span>
                </div>
              </div> 
            </div>
            <div className="content-part">
              <h5 className="title">Professional </h5>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-6 wow animate__animated animate__fadeInUp " style={{animationDelay: '0.11s', animationName: 'fadeInUp'}}>
          <div className="counter-area">
            <div className="counter-list mb-20">
              <div className="counter-icon">
                <img src="assets/img/Experience.png" alt="Years of Team Experience" />
                <div className="counter-number">
                  <span className="bo-count">
                    70</span>
                  <span style={{fontSize: 30, color: 'var(--brand-colour)'}}> Yrs +</span>
                </div>
              </div>
            </div>
            <div className="content-part">
              <h5 className="title"> Years of Team Experience
              </h5>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-6 xs-mb-30 wow animate__animated animate__fadeInUp " style={{animationDelay: '0.11s', animationName: 'fadeInUp'}}>
          <div className="counter-area">
            <div className="counter-list mb-20">
              <div className="counter-icon">
                <img src="assets/img/investors-ic.png" alt="Investors" />
                <div className="counter-number">
                  <span className="bo-count">25</span>
                  <span style={{fontSize: 30, color: 'var(--brand-colour)'}}> +</span>
                </div>
              </div>
            </div>
            <div className="content-part">
              <h5 className="title"> Investors
              </h5>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-6 wow animate__animated animate__fadeInUp " style={{animationDelay: '0.11s', animationName: 'fadeInUp'}}>
          <div className="counter-area">
            <div className="counter-list mb-20">
              <div className="counter-icon">
                <img src="assets/img/client.png" alt="Clients Served" />
                <div className="counter-number">
                  <span style={{fontSize: 64, fontWeight: 200, color: '#f26624'}} className="bo-count">1500</span>
                  <span style={{fontSize: 30, color: 'var(--brand-colour)'}}>+</span>
                </div>
              </div>
            </div>
            <div className="content-part">
              <h5 className="title">Clients Served
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="row" style={{marginTop: 30}}>
      <div className="col-lg-3 col-md-6 col-sm-6 md-mb-30 wow animate__animated animate__fadeInUp " style={{animationDelay: '0.11s', animationName: 'fadeInUp'}}>
      </div>
      <div className="col-lg-3 col-md-6 col-sm-6 wow animate__animated animate__fadeInUp " style={{animationDelay: '0.11s', animationName: 'fadeInUp'}}>
        <div className="counter-area">
          <div className="counter-list mb-20">
            <div className="counter-icon">
              <img src="assets/img/mortgage-loan.png" alt="Loan Disbursed" />
              <div className="counter-number">
                <i className="fa fa-rupee" aria-hidden="true" style={{fontSize: 24, color: '#f26624'}} />
                <span className="bo-count">
                  50</span>
                <span style={{fontSize: 30, color: 'var(--brand-colour)'}}> Cr +</span>
              </div>
            </div>
          </div>
          <div className="content-part">
            <h5 className="title"> Loan Disbursed
            </h5>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6 col-sm-6 xs-mb-30 wow animate__animated animate__fadeInUp " style={{animationDelay: '0.11s', animationName: 'fadeInUp'}}>
        <div className="counter-area">
          <div className="counter-list mb-20">
            <div className="counter-icon">
              <img src="assets/img/price.png" alt="Assets Managed" />
              <div className="counter-number">
                <i className="fa fa-rupee" aria-hidden="true" style={{fontSize: 24, color: '#f26624'}} />
                <span className="bo-count">1600 </span>
                <span style={{fontSize: 30, color: 'var(--brand-colour)'}}>Cr+</span>
              </div>
            </div>
          </div>
          <div className="content-part">
            <h5 className="title"> Assets Managed
            </h5>
          </div>
        </div>
      </div>
      <div className="col-lg-3 col-md-6 col-sm-6 wow animate__animated animate__fadeInUp " style={{animationDelay: '0.11s', animationName: 'fadeInUp'}}>
      </div>
    </div>
  </div>
  <section className="ourteam">
    <div className="team style1 team-style1  pb-100 md-pt-70 md-pb-70">
      <div className="bo">
        <h2>Our Growth Catalysts</h2>
        <p>Meet our growth catalyst, the dynamic leadership team driving our success.</p>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="tabs-ourteam">
            <input type="radio" name="tabs" id="tabone" defaultChecked="checked" />
            <label htmlFor="tabone">Management Team</label>
            <div className="tab">
              <div className="tab-content" id="v-pills-tabContent" style={{marginTop: 5}}>
                <div className="tab-pane fade pt-4 me-4 active show" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                  <div className="accordion" id="accordionPanelsStayOpenExample">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-4 wow animate__animated animate__fadeInUp " style={{animationDelay: '0.2s', animationName: 'fadeInUp'}}>
                          <div className="team-wrap">
                            <div className="image-wrap">
                              <a href="#" datacls-bs-toggle="modal" data-bs-target="#exampleModal"><img src="assets/img/prasoon-ceo.png" alt="Founder & CEO, Blackopal Group" /></a>
                            </div>
                            <div className="team-content">
                              <h3 className="team-name"><a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal">Prasoon
                                  Chauhan</a></h3>
                              <span className="team-title">Founder &amp; CEO</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 wow animate__animated animate__fadeInUp " style={{animationDelay: '0.2s', animationName: 'fadeInUp'}}>
                          <div className="team-wrap ourteam-m-t-b">
                            <div className="image-wrap">
                              <a href="#" data-bs-toggle="modal" data-bs-target="#ishanModal"><img src="assets/img/ishan.png" alt="Team" /></a>
                            </div>
                            <div className="team-content">
                              <h3 className="team-name"><a href="#" data-bs-toggle="modal" data-bs-target="#ishanModal">Ishan Agarwal
                                </a></h3>
                              <span className="team-title">Co-Founder &amp; COO
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 wow animate__animated animate__fadeInUp " style={{animationDelay: '0.1s', animationName: 'fadeInUp'}}>
                          <div className="team-wrap">
                            <div className="image-wrap">
                              <a href="#" data-bs-toggle="modal" data-bs-target="#priyaModal"><img src="assets/img/priya.png" alt="Team" /></a>
                            </div>
                            <div className="team-content">
                              <h3 className="team-name"><a href="#" data-bs-toggle="modal" data-bs-target="#priyaModal">Priya Tawakley
                                </a></h3>
                              <span className="team-title">CHRO</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-5">
                        <div className="col-lg-4 offset-lg-2 wow animate__animated animate__fadeInUp " style={{animationDelay: '0.11s', animationName: 'fadeInUp'}}>
                          <div className="team-wrap ">
                            <div className="image-wrap">
                              <a href="#" data-bs-toggle="modal" data-bs-target="#gauravModal"><img src="assets/img/gaurav.png" alt="Head - Business & Strategy" /></a>
                            </div>
                            <div className="team-content">
                              <h3 className="team-name"><a href="#" data-bs-toggle="modal" data-bs-target="#gauravModal">Gaurav Bhardwaj
                                </a></h3>
                              <span className="team-title">Head - Business &amp; Strategy
                              </span>
                              <span className="team-title "> Financial
                                Services</span>
                            </div>
                          </div>
                        </div>
                        {/* <div className="col-lg-4 wow animate__animated animate__fadeInUp " style={{animationDelay: '0.1s', animationName: 'fadeInUp'}}>
                          <div className="team-wrap ourteam-m-t-b">
                            <div className="image-wrap ourteam-m-t-b">
                              <a href="#" data-bs-toggle="modal" data-bs-target="#auModal"><img src="assets/img/ashutosh.png" alt="Team" /></a>
                            </div>
                            <div className="team-content">
                              <h3 className="team-name"><a href="#" data-bs-toggle="modal" data-bs-target="#auModal">Ashutosh Kaithwar
                                </a></h3>
                              <span className="team-title">Head – Product
                              </span>
                              <span className="team-title "> Technology
                                Services </span>
                            </div>
                          </div>
                        </div> */}
                        <div className="col-lg-4 wow animate__animated animate__fadeInUp " style={{animationDelay: '0.1s', animationName: 'fadeInUp'}}>
                          <div className="team-wrap">
                            <div className="image-wrap">
                              <a href="#" data-bs-toggle="modal" data-bs-target="#KanvModal"><img src="assets/img/kanav.png" alt="Team" /></a>
                            </div>
                            <div className="team-content">
                              <h3 className="team-name"><a data-bs-toggle="modal" data-bs-target="#KanvModal">Kanv Bhalla</a></h3>
                              <span className="team-title">Principle Partner </span>
                              <span className="team-title "> Advisory Services
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="row mt-5">
                        <div className="col-lg-4 wow animate__animated animate__fadeInUp offset-md-4" style={{animationDelay: '0.1s', animationName: 'fadeInUp'}}>
                          <div className="team-wrap">
                            <div className="image-wrap">
                              <a href="#" data-bs-toggle="modal" data-bs-target="#lokeshModal"><img src="assets/img/lokesh.png" alt="Team" /></a>
                            </div>
                            <div className="team-content">
                              <h3 className="team-name"><a href="#lokeshModal">Lokesh Ahuja</a></h3>
                              <span className="team-title">Director</span>
                              <span className="team-title ">Advisory
                                Services</span>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <input type="radio" name="tabs" id="tabtwo" />
            <label htmlFor="tabtwo">Advisory Board</label>
            {/* <div className="tab">
              <div className="tab-content" id="v-pills-tabContent" style={{marginTop: 5}}>
                <div className="tab-pane fade pt-4 me-4 active show" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                  <div className="accordion" id="accordionPanelsStayOpenExample">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-4 wow animate__animated animate__fadeInUp " style={{animationDelay: '0.2s', animationName: 'fadeInUp'}}>
                          <div className="team-wrap">
                            <div className="image-wrap">
                              <a href="#" data-bs-toggle="modal" data-bs-target="#mandeepModal"><img src="assets/img/Mandeep.png" alt /></a>
                            </div>
                            <div className="team-content">
                              <h3 className="team-name"><a href="#" data-bs-toggle="modal" data-bs-target="#mandeepModal">Mandeep Singh Sobti</a></h3>
                              <span className="team-title"> Founder &amp; CEO- Sobtax, NY Inc &amp; 88 Gerard Associates LLC</span>   
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 wow animate__animated animate__fadeInUp " style={{animationDelay: '0.2s', animationName: 'fadeInUp'}}>
                          <div className="team-wrap ourteam-m-t-b">
                            <div className="image-wrap">
                              <a href="#" data-bs-toggle="modal" data-bs-target="#rakeshModal"><img src="assets/img/rakesh.png" alt="Team" /></a>
                            </div>
                            <div className="team-content">
                              <h3 className="team-name"><a href="#" data-bs-toggle="modal" data-bs-target="#rakeshModal">Rakesh Agarwal
                                </a></h3>
                              <span className="team-title">Ex-CEO, Dalmia Group Holdings
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 wow animate__animated animate__fadeInUp " style={{animationDelay: '0.1s', animationName: 'fadeInUp'}}>
                          <div className="team-wrap">
                            <div className="image-wrap">
                              <a href="#" data-bs-toggle="modal" data-bs-target="#amitModal"><img src="assets/img/amit.png" alt="Team" /></a>
                            </div>
                            <div className="team-content">
                              <h3 className="team-name"><a href="#" data-bs-toggle="modal" data-bs-target="#amitModal">Amit Sehgal
                                </a></h3>
                              <span className="team-title">Founder - Pharynxai at PharynxAI</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 wow animate__animated animate__fadeInUp " style={{animationDelay: '0.1s', animationName: 'fadeInUp'}}>
                        <div className="team-wrap ourteam-m-t-b">
                            <div className="image-wrap">
                              <a href="#" data-bs-toggle="modal" data-bs-target="#pradeepModal"><img src="assets/img/Pradeep.png" alt="Team" /></a>
                            </div>
                            <div className="team-content">
                              <h3 className="team-name"><a href="#" data-bs-toggle="modal" data-bs-target="#pradeepModal">Pradeep Pasari
                                </a></h3>
                              <span className="team-title">Ex- Finance & Accounts, Narotam Sekhsaria Family Office (NSFO)</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="tab">
    <div className="tab-content" id="v-pills-tabContent" style={{marginTop: 5}}>
        <div className="tab-pane fade pt-4 me-4 active show" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
            <div className="accordion" id="accordionPanelsStayOpenExample">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 wow animate__animated animate__fadeInUp " style={{animationDelay: '0.2s', animationName: 'fadeInUp'}}>
                            <div className="team-wrap">
                                <div className="image-wrap">
                                    <a href="#" data-bs-toggle="modal" data-bs-target="#mandeepModal"><img src="assets/img/Mandeep.png" alt /></a>
                                </div>
                                <div className="team-content">
                                    <h3 className="team-name"><a href="#" data-bs-toggle="modal" data-bs-target="#mandeepModal">Mandeep Singh Sobti</a></h3>
                                    <span className="team-title"> Founder &amp; CEO- Sobtax, NY Inc &amp; 88 Gerard Associates LLC</span>   
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 wow animate__animated animate__fadeInUp " style={{animationDelay: '0.2s', animationName: 'fadeInUp'}}>
                            <div className="team-wrap ourteam-m-t-b">
                                <div className="image-wrap">
                                    <a href="#" data-bs-toggle="modal" data-bs-target="#rakeshModal"><img src="assets/img/rakesh.png" alt="Team" /></a>
                                </div>
                                <div className="team-content">
                                    <h3 className="team-name"><a href="#" data-bs-toggle="modal" data-bs-target="#rakeshModal">Rakesh Agarwal</a></h3>
                                    <span className="team-title">Ex-CEO, Dalmia Group Holdings</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 wow animate__animated animate__fadeInUp " style={{animationDelay: '0.1s', animationName: 'fadeInUp'}}>
                            <div className="team-wrap">
                                <div className="image-wrap">
                                    <a href="#" data-bs-toggle="modal" data-bs-target="#amitModal"><img src="assets/img/amit.png" alt="Team" /></a>
                                </div>
                                <div className="team-content">
                                    <h3 className="team-name"><a href="#" data-bs-toggle="modal" data-bs-target="#amitModal">Amit Sehgal</a></h3>
                                    <span className="team-title">Founder - Pharynxai at PharynxAI</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 wow animate__animated animate__fadeInUp " style={{animationDelay: '0.1s', animationName: 'fadeInUp'}}>
                            <div className="team-wrap ourteam-m-t-b">
                                <div className="image-wrap">
                                    <a href="#" data-bs-toggle="modal" data-bs-target="#pradeepModal"><img src="assets/img/Pradeep.png" alt="Team" /></a>
                                </div>
                                <div className="team-content">
                                    <h3 className="team-name"><a href="#" data-bs-toggle="modal" data-bs-target="#pradeepModal">Pradeep Pasari</a></h3>
                                    <span className="team-title">Family Office Advisory Professional</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

          </div>
        </div>
      </div>
    </div>
  </section>
 <Footer/>

  </div>
<div>



  <div className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog ">
      <div className="modal-content modal-xl">
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel" />
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
        </div>
        <div className="modal-body">
          <div className="rs-contact contact-style2 md-pt-70 md-pb-70">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="container custom">
                    <div className="btm-info-team">
                      <div className="row align-items-center">
                        <div className="col-lg-3 md-mb-50">
                          <div className="images-part">
                            <img src="assets/img/prasoon-ceo.png" alt="images" />
                          </div>
                        </div>
                        <div className="col-lg-9">
                          <div className="con-info">
                            <h4>Prasoon Chauhan</h4>
                            <p>Founder &amp; CEO, Blackopal Group</p>
                            <div className="ps-informations">
                            </div>
                            <div className="short-desc">
                              <p>A serial entrepreneur with 17+ years of industry experience across Real Estate Financing, Project Development, Project Consulting and Banking. </p>
                              <p>Prior to founding Blackopal Group, he Co-founded ATS HomeKraft which was a JV for mid income housing development between HDFC Capital, ATS Group and himself. Under his leadership HomeKraft had projects under execution of more than 10 Mn Sq. ft. with sale value of over INR 5000 cr within a span of 18 months.</p>
                              <p>He has also worked with two of the finest private sector banks of the country namely ICICI Bank and Yes Bank with deep experience of lending in the Real Estate Segment. As the President &amp; MD of Yes Bank, he had scaled the corporate finance portfolio from $50 Mn to $2000 Mn across Real Estate, Hospitality, Healthcare &amp; Education sector.</p>
                              <p>He holds an MBA degree from IMT, Ghaziabad with short term program at London Business School and Manchester Business School, UK .</p>
                              <p />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="modal fade" id="ishanModal" tabIndex={-1} aria-labelledby="ishanModalLabel" aria-hidden="true">
    <div className="modal-dialog ">
      <div className="modal-content modal-xl">
        <div className="modal-header">
          <h5 className="modal-title" id="ishanModalLabel" />
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
        </div>
        <div className="modal-body">
          <div className="rs-contact contact-style2 md-pt-70 md-pb-70">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="container custom">
                    <div className="btm-info-team">
                      <div className="row align-items-center">
                        <div className="col-lg-3 md-mb-50">
                          <div className="images-part">
                            <img src="assets/img/ishan.png" alt="images" />
                          </div>
                        </div>
                        <div className="col-lg-9">
                          <div className="con-info">
                            <h4>Ishan Agarwal
                            </h4>
                            <p>Co-Founder &amp; COO
                            </p>
                            <div className="short-desc">
                              <p>An IIT Roorkee alumni with knack for entrepreneurship, founded his Boutique Investment Firm for developing Affordable Housing in joint venture with a leading developer in North India in 2014 with a cumulative exit value of $2.50 Mn</p>
                              <p>Has 7+ years of experience in Real Estate Sector
                                related to builders, intermediaries, customer
                                management and has a deep industry knowledge</p>
                              <p>He has 7+ years of experience in Real Estate Sector related to builders, intermediaries, customer management and has a deep industry knowledge and understanding of project financing, approval process, deal structuring (JV, JDA, DM or outright), land acquisition, market exploration &amp; real estate development etc.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="modal fade" id="priyaModal" tabIndex={-1} aria-labelledby="priyaModalLabel" aria-hidden="true">
    <div className="modal-dialog ">
      <div className="modal-content modal-xl">
        <div className="modal-header">
          <h5 className="modal-title" id="priyaModalLabel" />
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
        </div>
        <div className="modal-body">
          <div className="rs-contact contact-style2 md-pt-70 md-pb-70">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="container custom">
                    <div className="btm-info-team">
                      <div className="row align-items-center">
                        <div className="col-lg-3 md-mb-50">
                          <div className="images-part">
                            <img src="assets/img/priya.png" alt="images" />
                          </div>
                        </div>
                        <div className="col-lg-9">
                          <div className="con-info">
                            <h4>Priya Tawakley
                            </h4>
                            <p>CHRO
                            </p>
                            <div className="short-desc">
                              <p>An MBA by qualification, Priya has worked in the
                                Human Resources domain for the last 20+ years of her
                                career. After having worked with renowned companies
                                like KPMG, Jones Lang LaSalle and HDFC Bank, she
                                previously started her entrepreneurial journey with
                                Perfect Global Profiles, a recruitment solution firm
                              </p>
                              <p>As an end-to-end HR solution provider, she was
                                instrumental in setting up the entire HR function of
                                ATS HomeKraft, a Real Estate company. She scaled up
                                the manpower to a strength of 300+ and set up HR
                                Policies and the complete process flow. She has the
                                ability to design new HR processes and analyze and
                                redesign the existing processes for higher
                                efficiency. Her Forte includes Talent Acquisition,
                                Policy development, Comp &amp; Ben and Compliances
                                .
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="modal fade" id="gauravModal" tabIndex={-1} aria-labelledby="gauravModalLabel" aria-hidden="true">
    <div className="modal-dialog ">
      <div className="modal-content modal-xl">
        <div className="modal-header">
          <h5 className="modal-title" id="gauravModalLabel" />
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
        </div>
        <div className="modal-body">
          <div className="rs-contact contact-style2 md-pt-70 md-pb-70">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="container custom">
                    <div className="btm-info-team">
                      <div className="row align-items-center">
                        <div className="col-lg-3 md-mb-50">
                          <div className="images-part">
                            <img src="assets/img/gaurav.png" alt="images" />
                          </div>
                        </div>
                        <div className="col-lg-9">
                          <div className="con-info">
                            <h4>Gaurav Bhardwaj
                            </h4>
                            <p>Head – Business &amp; Strategy
                            </p>
                            <div className="short-desc">
                              <p>A Banking industry professional with experience of 7+ Years with RBL Bank in their Corporate &amp; Institutional Banking Group. Had scaled up the North India portfolio for financial institutions &amp; government group for RBL Bank from $5 Mn to $400 Mn.
                              </p>
                              <p>Have also worked with ICICI Bank in their SMEAG division. Gaurav is an MBA from IIM Shillong and majored in Finance. He did his Bachelors in Business Studies from Shaheed Sukhdev College of Business Studies, Delhi University.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="modal fade" id="auModal" tabIndex={-1} aria-labelledby="auModalLabel" aria-hidden="true">
    <div className="modal-dialog ">
      <div className="modal-content modal-xl">
        <div className="modal-header">
          <h5 className="modal-title" id="auModalLabel" />
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
        </div>
        <div className="modal-body">
          <div className="rs-contact contact-style2 md-pt-70 md-pb-70">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="container custom">
                    <div className="btm-info-team">
                      <div className="row align-items-center">
                        <div className="col-lg-3 md-mb-50">
                          <div className="images-part">
                            <img src="assets/img/ashutosh.png" alt="images" />
                          </div>
                        </div>
                        <div className="col-lg-9">
                          <div className="con-info">
                            <h4>Ashutosh Kaithwar
                            </h4>
                            <p>Head – Product
                            </p>
                            <div className="short-desc">
                              <p>An ISB alumnae with B. Tech from HBTI, having 10+
                                Years of experience in Corporate Strategy,
                                Consulting, and Valuation in the Core Real Estate
                                Industry. Having worked as North Head- Advisory
                                Services in Knightfrank, ATS Group, Brigade Group,
                                etc. </p>
                              <p> He is a tech-savvy professional having proven
                                deliverables in Strategizing complex business
                                problems. His logic-driven technical abilities make
                                him an excellent lead to build and scale the product
                                envisaged. In his free time he loves to explore
                                different coding languages, he is already a
                                certified python coder with machine learning
                                abilities.</p>
                              <p>He Heads the Product and technology development
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="modal fade" id="KanvModal" tabIndex={-1} aria-labelledby="KanvModalLabel" aria-hidden="true">
    <div className="modal-dialog ">
      <div className="modal-content modal-xl">
        <div className="modal-header">
          <h5 className="modal-title" id="KanvModalLabel" />
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
        </div>
        <div className="modal-body">
          <div className="rs-contact contact-style2 md-pt-70 md-pb-70">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="container custom">
                    <div className="btm-info-team">
                      <div className="row align-items-center">
                        <div className="col-lg-3 md-mb-50">
                          <div className="images-part">
                            <img src="assets/img/kanav.png" alt="images" />
                          </div>
                        </div>
                        <div className="col-lg-9">
                          <div className="con-info">
                            <h4>Kanv Bhalla
                            </h4>
                            <p>Principal Partner
                            </p>
                            <div className="short-desc">
                              <p>Kanv has 15+ years of experience in Business Development and a demonstrated history of working in the real estate industry. He has previously worked in Citi Bank, Yes Bank and later started his own ventures. He is skilled in Business Planning, Sales, Management, and team building. His collaborative abilities make him an excellent leader who can chart his own course independently.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="modal fade" id="lokeshModal" tabIndex={-1} aria-labelledby="lokeshModalLabel" aria-hidden="true">
    <div className="modal-dialog ">
      <div className="modal-content modal-xl">
        <div className="modal-header">
          <h5 className="modal-title" id="lokeshModalLabel" />
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
        </div>
        <div className="modal-body">
          <div className="rs-contact contact-style2 md-pt-70 md-pb-70">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="container custom">
                    <div className="btm-info-team">
                      <div className="row align-items-center">
                        <div className="col-lg-3 md-mb-50">
                          <div className="images-part">
                            <img src="assets/img/lokesh.png" alt="images" />
                          </div>
                        </div>
                        <div className="col-lg-9">
                          <div className="con-info">
                            <h4>Lokesh Ahuja</h4>
                            <p>Director- Sales<br />
                              Advisory Services</p>
                            <div className="ps-informations">
                            </div>
                            <div className="short-desc">
                              <p>Lokesh has 20+ years of experience in business development in retail financial services &amp; real estate. He has previously worked with Indiabulls, SARE group, and ICICI Wealth.  He is driven about process, productivity and profitability, steeped in sincerity, integrity and motivation He is proficient in managing P&amp;L while achieving sustainable growth in revenue and profits.</p>
                              <p />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="modal fade" id="rakeshModal" tabIndex={-1} aria-labelledby="rakeshModalLabel" aria-hidden="true">
    <div className="modal-dialog ">
      <div className="modal-content modal-xl">
        <div className="modal-header">
          <h5 className="modal-title" id="rakeshModalLabel" />
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
        </div>
        <div className="modal-body">
          <div className="rs-contact contact-style2 md-pt-70 md-pb-70">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="container custom">
                    <div className="btm-info-team">
                      <div className="row align-items-center">
                        <div className="col-lg-3 md-mb-50">
                          <div className="images-part">
                            <img src="assets/img/rakesh.png" alt="images" />
                          </div>
                        </div>
                        <div className="col-lg-9">
                          <div className="con-info">
                            <h4>Rakesh Agarwal
                            </h4>
                            <p>Ex-CEO, Dalmia Group Holdings
                            </p>
                            <div className="short-desc">
                              <p>Rakesh Aggarwal a qualified Chartered Accountant has 38+ years of experience in various areas of Finance &amp; Accounts and other commercial functions with companies like Jagatjit Industries, HCL Group, a Corning US joint venture and Dalmia Group. The experience includes 13+ years in the areas of Private Equity &amp; Structured debt including off-shore investments and exit for total value exceeding $300 Mn. Experience encompasses entire gambit including origination, post deal management, documentation &amp; FEMA and exits having complex structures.</p>
                              <p>Rakesh has excellent knowledge of corporate intelligence and customer market understanding emanating from his vast &amp; wide spectrum experience.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="modal fade" id="amitModal" tabIndex={-1} aria-labelledby="amithModalLabel" aria-hidden="true">
    <div className="modal-dialog ">
      <div className="modal-content modal-xl">
        <div className="modal-header">
          <h5 className="modal-title" id="amitModalLabel" />
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
        </div>
        <div className="modal-body">
          <div className="rs-contact contact-style2 md-pt-70 md-pb-70">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="container custom">
                    <div className="btm-info-team">
                      <div className="row align-items-center">
                        <div className="col-lg-3 md-mb-50">
                          <div className="images-part">
                            <img src="assets/img/amit.png" alt="images" />
                          </div>
                        </div>
                        <div className="col-lg-9">
                          <div className="con-info">
                            <h4>Amit Sehgal </h4>
                            <p>Founder - Pharynxai at PharynxAI </p>
                            <div className="short-desc">
                              <p>Amit is an IIMA, IIIT-B alumni with a rich &amp; diverse body or work to his credit. He has over two decades of experience in various roles in product management, customer engagement, business development, market analysis, strategy, innovations &amp; IPR with organisations such as CGI, Nokia, GSMA.  He now runs his startup working on new age tech development and industry impacting product roll outs. Amit’s personal interest lies in innovation, strategy &amp; marketing, and behavioral economics.</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div className="modal fade" id="pradeepModal" tabIndex={-1} aria-labelledby="pradeepModalLabel" aria-hidden="true">
  <div className="modal-dialog ">
    <div className="modal-content modal-xl">
      <div className="modal-header">
        <h5 className="modal-title" id="pradeepModalLabel" />
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
      </div>
      <div className="modal-body">
        <div className="rs-contact contact-style2 md-pt-70 md-pb-70">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="container custom">
                  <div className="btm-info-team">
                    <div className="row align-items-center">
                      <div className="col-lg-3 md-mb-50">
                        <div className="images-part">
                          <img src="assets/img/Pradeep.png" alt="images" />
                        </div>
                      </div>
                      <div className="col-lg-9">
                        <div className="con-info">
                          <h4>Pradeep Pasari</h4>
                          <p>Family Office Advisory Professional</p>
                          <div className="short-desc">
                              <p>A Finance Professional with more than 30 years of experience in Strategy, Investments, Regulatory Affairs and Taxation in diversified industries and Business Houses. Mr. Pasari has worked both with renowned Corporates and Family Offices of promoters including groups like Hinduja Group, Wadia Group, Huhtamaki Group, Sanjaya Mariwala Group and Sekhsaria Family Office. He is currently working with the family office of a renowned promoter family headquartered in the NCR region.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

  <div className="modal fade" id="mandeepModal" tabIndex={-1} aria-labelledby="mandeepModalLabel" aria-hidden="true">
  <div className="modal-dialog ">
    <div className="modal-content modal-xl">
      <div className="modal-header">
        <h5 className="modal-title" id="mandeepModalLabel" />
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
      </div>
      <div className="modal-body">
        <div className="rs-contact contact-style2 md-pt-70 md-pb-70">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="container custom">
                  <div className="btm-info-team">
                    <div className="row align-items-center">
                      <div className="col-lg-3 md-mb-50">
                        <div className="images-part">
                          <img src="assets/img/Mandeep.png" alt="images" />
                        </div>
                      </div>
                      <div className="col-lg-9">
                        <div className="con-info">
                          <h4>Mandeep Sobti</h4>
                          <p>Founder &amp; CEO- Sobtax, NY Inc &amp; 88 Gerard Associates LLC</p>
                          <div className="short-desc">
                            <p>Mandeep Sobti is the founder of Sobtax, NY Inc &amp; 88 Gerard Associates LLC and serves as its President &amp; CEO, which owns and operates 45 Jackson Hewitt income tax offices in 4 states: New York, New Jersey, Connecticut, and Louisiana. He previously oversaw highly successful Jackson Hewitt firms in Texas, Massachusetts, and Virginia.</p>
                            <p>Beyond his accounting industry work, Mr. Sobti is an avid runner. He can be regularly spotted running in prestigious races, most notably The NYC and other Marathons. Beyond his professional endeavors, Mandeep Sobti supports charitable organizations and causes. Mr. Sobti is also one of the founding trustees of the Punjabi Chamber of Commerce in the USA.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
</div>

       
            
        


</div>




  )
}

export default Aboutus