import React from 'react';
import Header from '../components/Header';
import Footer from "../components/Footer";
import { Helmet } from 'react-helmet';


const FinancialServices = () => {
  const pageTitle = "Financial Services";
  const videoStyle = {
    border: 'none', // Remove border
    borderRadius: '0px 0px 0px 39px',
   
   
  };
  return (

    <>
    <Helmet>
        <title>{pageTitle}</title>
        
      </Helmet> 
 <div class="main-content">
  
 <Header/>

  <section className="about">
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <div className="unleasing">
            <h2>
              UNLEASHING
              POTENTIAL</h2>
            <p>
              Focussed on solving the core problem of credit availability at the grass root level, we at Black Opal Financial Services- a tech enabled, RBI registered non deposit taking NBFC, provide flexible and customized financing options easily accessible through our tech platform. We firmly believe in our bedrock belief of providing access to capital at all levels, as it is crucial for sustainable growth.
            </p>
          </div>
        </div>
        <div className="col-md-6">
          <div className="mb-4 mb-lg-0 video-wrapperr header-img ">
        
        <video poster="assets/img/UNLEASHING.jpg"
 
   src= '/Video/unleasing.mp4'
   style={videoStyle}
   autoPlay={true}
preload={'auto'}
muted={true}
playsinline
// controls
loop={true}
   width='100%'
   type="video/mp4"
 
  />  
         
         
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="services" id="offering" style={{background: '#fbfbfb'}}>
    <div className="container">
      <div className="row">
        <div className="col-lg-6 ">
          <div className="main-title text-left">
            <h2 style={{color: '#f26624'}}>Product Offerings</h2>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 col-lg-4 col-xl-4">
          
            <div className="for_blog feat_property animate__animated animate__fadeInUp animate__delay-2s">
              <div className="thumb2 overlay">
                <h3 className="info-title h8">
                  Early Brokerage                          </h3>
                <img className="img-whp borderhome" alt='Early Brokerage' src="assets/img/early.jpg" />
              </div>
              <div className="details">
                <div className="tc_content">
                  <p className="text-thm">A first of its kind working capital product for the agents who play a pivotal role in the real estate industry. Recognizing their contribution, we are committed to nurturing this ecosystem by offering easily accessible financing options. The product aims to empower and support the agents by ensuring financial resources they need to thrive.
                  </p>
                </div>
              </div>
            </div>
        
        </div>
        <div className="col-md-6 col-lg-4 col-xl-4">
        
            <div className="for_blog feat_property animate__animated animate__fadeInUp animate__delay-2s">
              <div className="thumb2 overlay">
                <h3 className="info-title h8">Home Equity</h3>
                <img className="img-whp borderhome" alt='Home Equity' src="assets/img/Home.jpg" />
              </div>
              <div className="details">
                <div className="tc_content">
                  <p className="text-thm">
                    An intelligent and cutting-edge solution designed to address the initial contribution gap that often arises when purchasing your dream home. This innovative product is specifically crafted to provide financial assistance for the capital shortfall faced by customers during the initial stages of the home-buying process.
                  </p>
                </div>
              </div>
            </div>
        </div>
        <div className="col-md-6 col-lg-4 col-xl-4">
         
            <div className="for_blog feat_property animate__animated animate__fadeInUp animate__delay-2s">
              <div className="thumb2 overlay">
                <h3 className="info-title h8">Home Improvement 
                </h3>
                <img className="img-whp borderhome" alt="Home Improvement " src="assets/img/improvement.jpg" />
              </div>
              <div className="details">
                <div className="tc_content">
                  <p className="text-thm">A unique product that bridges the gap between aspirations and the budget, transforming a house into your dream home. It provides you with the means to create a living space that reflects your personality, style, and aspirations, ensuring that every corner of your home brings you joy and fulfillment.
                  </p>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
    
    </section>


  <div className="rs-about  pb-100 md-pt-70 md-pb-70">
    <div className="container-fluid">
      <div className="row y-middle">
        <div className="col-lg-6 pl-220 md-pr-15 md-mb-50 animate__animated animate__fadeInLeft animate__delay-2s">
          <div className="sec-title2 mb-30 abouttop">
            <h2 className="title mb-23">MSME &amp; Corporate Lending</h2>
            <div className="about-img eco-web">
              <img src="assets/img/MSME.jpg" className="borderbottom" alt="images" />
            </div>
            <p className="mb-0 aboutp">We understand the unique needs and challenges faced by Corporates, MSMEs and SMEs, and our platform is designed to cater to their specific requirements. We offer a range of financing options that align with the growth aspirations of these businesses. Our key differentiator lies in our analytical approach to credit verification. By leveraging technology and utilizing multiple data points, we meticulously assess the creditworthiness of our borrowers. Additionally, we are dedicated to building a robust lending platform specifically tailored for our SME clients.
            </p>
          </div>
        </div>
        <div className="col-lg-6  animate__animated animate__fadeInRight animate__delay-3s pos">
          <div className="about-img eco-img">
            <img src="assets/img/MSME.jpg" alt="MSME &amp; Corporate Lending" className="borderbottom" />
          </div>
        </div>
      </div>
    </div>
  </div>

<Footer/>
 
</div>
    </>


  )
}

export default FinancialServices
