import React, { useState } from 'react';
//  import { CNavbar, CContainer, CNavbarBrand, CNavItem, CNavLink, CNavbarToggler, CNavbarNav, CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem } from '@coreui/react';
import { NavLink } from 'react-router-dom';

const Header = () => {

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  
    const handleDropdownHover = () => {
      setIsDropdownOpen(!isDropdownOpen);
    };

  return (
 
    <>
   

    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container-fluid">
        <NavLink className="navbar-brand" to="/">
          <img src="assets/img/logo-dark.png" alt="Blackopal" width={140} height={50} className='logo-20px' />
        </NavLink>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mb-2 mb-lg-0">

            <li className="nav-item">
              <NavLink className="nav-link" activeClassName="active" to="/Aboutus">
                About Us
              </NavLink>
            </li>

            <li  className= {`dropdown ${isDropdownOpen ? 'open' : ''}`}
        onMouseEnter={handleDropdownHover}
        onMouseLeave={handleDropdownHover} >

              <NavLink
                className="nav-link dropdown-toggle "
                to="/"
               
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Services
              </NavLink>
              <ul className={`dropdown-menu ${isDropdownOpen ? 'show' : ''}`} aria-labelledby="navbarDropdown" >
                <li>
                  <NavLink className="dropdown-item" to="/FinancialServices">
                    Financial Services
                  </NavLink>
                </li>
                <li>
                  <NavLink className="dropdown-item" to="/TechnologyServices">
                    Technology Services
                  </NavLink>
                </li>
                <li>
                  <NavLink className="dropdown-item" to="/AdvisoryServices">
                    Advisory Services
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" activeClassName="active" to="/Career">
                Career
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" activeClassName="active" to="/Media">
                Media
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" activeClassName="active" to="/Contactus">
                Contact Us
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>


  </>

  )
}

export default Header;