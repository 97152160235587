
import React from "react";

import { Routes, Route, BrowserRouter } from "react-router-dom"
import Home from './Pages/Home';
import Aboutus from './Pages/Aboutus';
import Career from './Pages/Career';
import Media from './Pages/Media';
import Contactus from './Pages/Contactus';
import FinancialServices from './Pages/FinancialServices';
import TechnologyServices from './Pages/TechnologyServices';
import AdvisoryServices from './Pages/AdvisoryServices';
import Termsofuse from "./Pages/Termsofuse";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
// import HomePage from "./Pages/HomePage";





function App() {
    return (

 
     
 <BrowserRouter>

   <Routes>
    {/* <Route   path='/' element={<HomePage />} /> */}
    <Route  path='/' element={<Home />} />
    <Route  path='/Aboutus' element={<Aboutus />} />
    <Route  path='/FinancialServices' element={<FinancialServices />} />
    <Route  path='/TechnologyServices' element={<TechnologyServices />} />
    <Route  path='/AdvisoryServices' element={<AdvisoryServices />} />
     <Route  path='/Career' element={<Career />} />
     <Route  path='/Media' element={<Media />} />
     <Route  path='/Contactus' element={<Contactus />} />
     <Route  path='/Termsofuse' element={<Termsofuse />} />
     <Route  path='/PrivacyPolicy' element={<PrivacyPolicy />} />


   </Routes>

</BrowserRouter>

    
  );
}

export default App;
