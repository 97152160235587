import React from 'react'
import Header from '../components/Header';
import Footer from "../components/Footer";
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';


const Career = () => {
  const pageTitle = "Career";
  const videoStyle = {
    border: 'none', // Remove border

  };

  return (


    <>
      <Helmet>
        <title>{pageTitle}</title>

      </Helmet>
      <div class="main-content">

        <Header />
        <section className="career-wrapper">
          {/* <video autoPlay muted loop>
          <source src="assets/img/careers.mp4" type="video/mp4" />
          </video> */}
          <video poster="assets/img/carrer-poster.jpg"

            src='/assets/img/careers.mp4'
            style={videoStyle}
            autoPlay={true}
            preload={'auto'}
            muted={true}
            // controls
            loop={true}


          />




        </section>
        <section className="career-page">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-md-6">
                <div className="careers-section">
                  <h2>YOU BELONG HERE!</h2>
                  <p>The idea of building a tech enabled prop fintech ecosystem had occupied numerous evenings and thousands of pages in our diaries for many years.
                  </p><p>
                  </p><p>The result? A fully integrated ecosystem that empowers customers throughout their home buying journey. With great zeal, we continue to refine our vision and invite you to join the revolution. Together, we'll shape the future of real estate.
                  </p>
                  <p>As we forge ahead, embrace the untapped possibilities, and let us guide you through the exciting process of finding your dream home. Together, we'll unlock a new era of real estate innovation. Discover the power of Blackopal and embark on a journey that will redefine the way you think about home buying.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="carrer-section">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="proptech-cont careers-cont">
                  <div className="eco-web">
                  </div>
                  <h3>Let's Build Something Great Together </h3>
                  <p>
                    At Blackopal, we embrace the mission to challenge the status quo with extraordinary gumption and an unwavering belief in our collective vision. Our culture is rooted in disruptive innovation, fueling our relentless focus on solving customer problems. Ideas are not just welcomed but celebrated. We foster a meritocratic environment where every individual has an equal opportunity to contribute and excel. Collaboration and open dialogue are encouraged, creating an atmosphere of shared knowledge and collective growth. By valuing diverse perspectives, we drive innovation forward and empower our team members to think boldly and creatively.
                  </p>
                  <p>Together, we are building a future where possibilities are endless and customer-centric solutions redefine the real estate landscape.
                  </p></div>
              </div>
              <div className="col-md-6">
                <div className="carrer-img ">
                  <div className="gallery">
                    <div className="gallery__column">
                      <a href="" class="gallery__link">
                        <figure className="gallery__thumb">
                          <img src="assets/img/carrer02.jpg" alt="The fun begins here" className="gallery__image" />
                          <figcaption className="gallery__caption">The fun begins here..</figcaption>
                        </figure>
                      </a>
                      <a href="" class="gallery__link">
                        <figure className="gallery__thumb">
                          <img src="assets/img/carrer05.jpg" alt="The collaborative environment fosters growth" className="gallery__image" />
                          <figcaption className="gallery__caption">The collaborative environment fosters growth, creativity, and teamwork, creating a fulfilling work experience.</figcaption>
                        </figure>   </a>
                    </div>

                    <div className="gallery__column">
                      <a href="" class="gallery__link">
                        <figure className="gallery__thumb">
                          <img src="assets/img/carrer04.jpg" alt="Create, Innovate, Repeat" className="gallery__image" />
                          <figcaption className="gallery__caption">"Create, Innovate, Repeat"</figcaption>
                        </figure>
                      </a>

                      <a href="" class="gallery__link">
                        <figure className="gallery__thumb">
                          <img src="assets/img/carrer03.jpg" alt="I love coming to work every day because of the positive and inclusive atmosphere" className="gallery__image" />
                          <figcaption className="gallery__caption">I love coming to work every day because of the positive and inclusive atmosphere.</figcaption>
                        </figure>
                      </a>
                      <a href="" class="gallery__link">
                        <figure className="gallery__thumb">
                          <img src="assets/img/carrer01.jpg" alt="Recharge with a steaming cup of inspiration." className="gallery__image" />
                          <figcaption className="gallery__caption">Recharge with a steaming cup of inspiration. </figcaption>
                        </figure>
                      </a>
                    </div>
                    <div className="gallery__column">
                      <a href="" class="gallery__link">
                        <figure className="gallery__thumb">
                          <img src="assets/img/300x500.jpg" alt='I was not looking for a job' className="gallery__image" />
                          <figcaption className="gallery__caption">I was not looking for a job, I was looking for a mission.</figcaption>
                        </figure>
                      </a>
                      <a href="" class="gallery__link">
                        <figure className="gallery__thumb">
                          <img src="assets/img/carrer06.jpg" alt="Work Hard, Play Hard" className="gallery__image" />
                          <figcaption className="gallery__caption">Work Hard, Play Hard.</figcaption>
                        </figure>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="approach-area">
          <div className="container">
            <div className="bo">
              <h2>Why BlackOpal?</h2>
              <p />
            </div>
            <div className="row offset-md-2">
              <div className="col-xl-6 col-lg-6 col-md-6">
                <div className="counter-wrapper text-left mb-30">
                  <div className="counter-icon"><img src="assets/img/Innovate-ico.png" alt='Innovate' style={{ width: 50, marginBottom: 10 }} />
                    <div className="count-number">
                      <span className="approach">Innovate</span>
                      <p>A strong emphasis on disrupting traditional systems in real estate by creating innovative products and services. The work environment is fast-paced and highly dynamic, with a focus on rapid iteration and innovation.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6">
                <div className="counter-wrapper text-left mb-30">
                  <div className="counter-icon"><img src="assets/img/Community.png" alt="Build a Community" style={{ width: 50, marginBottom: 10 }} />
                    <div className="count-number">
                      <span className="approach">Build a Community</span>
                      <p>Building a vibrant community, we create value while driving social and economic change. With diversity and inclusion at our core, we empower individuals to bring their authentic selves, fostering collaboration and impactful contributions.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row offset-md-2 mt-5">
              <div className="col-xl-6 col-lg-6 col-md-6">
                <div className="counter-wrapper text-left mb-30">
                  <div className="counter-icon"><img src="assets/img/Ideas-ico.png" alt="All Ideas Matter" style={{ width: 50, marginBottom: 10 }} />
                    <div className="count-number">
                      <span className="approach">All Ideas Matter
                      </span>
                      <p>Leadership is approachable and transparent, fostering a sense of trust and accountability among employees. Feedback is regularly given and heard, and there are opportunities for growth and development. </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6">
                <div className="counter-wrapper text-left mb-30">
                  <div className="counter-icon"><img src="assets/img/Inclusion.png" alt='Inclusion' style={{ width: 50, marginBottom: 10 }} />
                    <div className="count-number">
                      <span className="approach">Inclusion</span>
                      <p>We foster an inclusive environment that promotes mentorship, growth, and intelligent discussions. We cultivate camaraderie and teamwork, encouraging employees to think innovatively and unleash their creativity to deliver unique solutions.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="opening-bo">
          <div className="container">
            <div className="open_heading text-center">
              <h2 className="positions_text mb-0">
                Opportunities are infinite,<br />
                <span>Find Yours.</span>
              </h2>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-8">
                <div className="tabs-career">
                  <input type="radio" name="tabs" id="tabtwo" defaultChecked="checked" />
                  <label htmlFor="tabtwo">Financial Services</label>
                  <div className="tab">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="false" aria-controls="panelsStayOpen-collapseOne">
                          <div className="open-position">Senior Manager (Accounts & Finance) - Real Estate<div className="city" style={{ marginTop: '-9px' }}>Noida</div>
                          </div>
                        </button>
                      </h2>
                      <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingOne" style={{ border: '1px solid #ebe7e7' }}>
                        <div className="accordion-body">
                          <div className="desiredCandidate">
                            <p><strong>Designation :</strong><br />Senior Manager (Accounts & Finance) - Real Estate
                            </p>
                            <p><strong>Experience :</strong><br /> 5 Years of Experience
                            </p>
                            <p><strong>Location: </strong><br />Noida</p>
                            <p><strong>Minimum Requirements :</strong></p>
                            <ul>
                              <li>CA with at least 5 years of Experience in the real estate industry</li>
                              <li>Handle the entire finance and accounting operations for the group comprising of multiple companies
                              </li>
                              <li>Strong communication and presentation skills including strong written and spoken English
language skills</li>
                              <li>Franchise and Brand building activities</li>
                              <li>In depth knowledge of Microsoft Office</li>
                            </ul><br />
                            <p><strong>Key Responsibilities:</strong></p>

                                <ul>
                                  <strong>Accounts and Audit</strong>
                                  <li>Handle the entire gamut of accounting & finance operations entailing preparation & maintenance of statutory books of accounts</li>
                                  <li>Finalization of year-end per listing requirements / half yearly or periodic financial statements including trial balance, bank reconciliation reports, profit & loss account, age-wise accounts payables & receivables statements and balance sheets</li>
                                  <li>Handling of various M & A projects including financial due diligence, evaluation of financial parameters of the deals</li>
                                  <li>Periodic monitoring, review of financial data that includes future projections.</li>
                                  <li>Handling of investors concerns pertaining to financial reporting and periodic compliance of terms and conditions agreed with investors</li>
                                  <li>MIS, Budgeting and forecasting</li>
                                  <li>Monitoring cash flows and ensuring that funds are arranged in the most cost-effective manner after projecting accurate cash forecast ensuring that there is no shortage of cash in hand</li>
                                </ul>
                                <p></p>
                                <ul>
                                  <strong>Taxation</strong>
                                  <li>Corporate taxation matters including tax planning. Compliance of GST, sales tax and works contract taxes in respect of projects being undertaken at different locations</li>
                                  <li>Various Joint Ventures Accounting, Direct & Indirect Tax Compliances and Assessments.</li>
                                  <li>Dealing with the Statutory & Internal auditor.</li>
                                  <li>Dealing with Customers, Venders, and Partners on Finance and Taxation related matters.</li>
                                </ul>
                                <p></p>
                                <ul>
                                  <strong>Project Management</strong>
                                  <li>Manage and monitor residential projects including contract management, budgeting, accounting, taxation and disposal matters.</li>
                                  <li>Advising project team, purchase team & Business Development Team on financial matters for further dealing with customers, vendors, and partners</li>
                                </ul>
                                <p></p>
                                <ul>
                                  <strong>Cost Controls and Rationalization</strong>
                                  <li>Raising funds from Banks, Financial Institutions and NBFCs. Well versed in the process of fund raising including, loan documentation, negotiating with the lenders for raising loans and restructuring of the existing loans.</li>
                                  <li>Oversee reconciliation of bank statements and resolve any discrepancies.</li>
                                </ul>
                                <p> </p>
                                <br></br>
                            <p><strong> About BlackOpal :</strong></p>
                            <p><strong>Black Opal Financial Services Private Limited (BOFS)</strong> – A sector agnostic NBFC engaged in the business of providing flexible credit solutions for acquisition, last mile financing, inventory funding etc. to SMEs and large corporates.
                              Our financing solutions include:
                              Corporate Solutions:
                            </p>
                            <p>1.Working Capital solutions: We offer short term financial solutions to our corporate partners to meet their cash flow mismatches and support day to day business operations without any hassles </p>
                            <p>2.Value Chain Financing: We offer sales invoice and purchase invoice discounting products to our corporate partners for vendor financing and supplementing cash flow requirements, reducing financing cost and improving profitability &amp; growth liquidity</p>
                            <p>3.Term Capital solutions: We offer tailored financing solutions to meet the objectives to support expansions, buyouts and diversifications through customized term loans, inter-corporate deposits, subscription to debt instruments etc. We also offer promoter funding secured against property, pledge of marketable securities or fixed deposits, guarantee of corporate entity, owner or promoter among others
                            </p>
                            <p>4.Invoice Discounting for our Agents and Broker Network: In order to free up liquidity at the end of our agent and broker network, we offer invoice discounting for invoices raised on our builder partners with whom we have signed MoUs</p>
                            <p><strong>Black Opal Consultants Private Limited (BOC) </strong>– BOC provides fully integrated asset management services for real estate projects. Currently BOC has AUM of 2.5 MM sq.ft. (valued at INR 1.75 Bn) managed through a strong in house team of 40+ professionals across 6 projects. The distinct USP of BOC is its ability to enter at every stage of the Project Lifecycle across Sales &amp; Marketing, CRM, Construction, Design &amp; Financial Management
                            </p>
                            <p> <strong> Black Opal Technologies Private Limited (BOT)</strong> – BOT is building the first Prop-Fintech platform to bridge the gaps in the customer lifecycle while buying, selling and financing of real estate sector</p>
                            <span>Please find us at:</span>
                            <div className="findus">

                              Interested candidates Kindly share your cv on <a href="mailto:hr@blackopalgroup.in">hr@blackopalgroup.in</a>
                              for more details contact 0120-5109230
                              <div style={{ display: 'flex', marginLeft: '-16', marginTop: '5', justifyContent: 'space-around' }} className='car'>

                                <a href="https://www.facebook.com/Blackopal-Group-105304038115767/?ref=pages_you_manage" target="_blank"><span><i className="fa fa-facebook" /></span></a>

                                <a href="https://www.linkedin.com/company/blackopal-group/" target="_blank"><span><i className="fa fa-linkedin" /></span></a>


                                <a href="https://www.instagram.com/blackopalgroup/?igshid=YmMyMTA2M2Y=" target="_blank"><span><i className="fa fa-instagram" /></span></a>


                                <a href="https://www.youtube.com/channel/UCQAni1Yzdw2FcYBItc7tc8g" target="_blank"><span><i className="fa fa-youtube" /></span></a>

                              </div>
                            </div>
                            <div className="applybtnBox">
                              {/* <button className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">Apply
                                Now</button> */}
                              <button className="btn btn-primary" ><a href="mailto:hr@blackopalgroup.in"> <span style={{ color: "#fff" }}>Apply
                                Now</span></a></button>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <input type="radio" name="tabs" id="tabone" />
                  <label htmlFor="tabone">Techonology Services</label>
                  <div className="tab">
                    <div className="tab-content" id="v-pills-tabContent" style={{ marginTop: 5 }}>
                      <div className="tab-pane fade pt-4 me-4 active show" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                        <div className="accordion" id="accordionPanelsStayOpenExample">
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
                                <div className="open-position">Associate Manager- Growth Operations (Partner Success) <div className="city" style={{ marginTop: '-9px' }}>Noida</div>
                                </div>
                              </button>
                            </h2>
                            <div id="panelsStayOpen-collapseThree" className="accordion-collapse collapse " aria-labelledby="panelsStayOpen-headingThree" style={{ border: '1px solid #ebe7e7' }}>
                              <div className="accordion-body">
                                <div className="desiredCandidate">
                                  <p><strong>Designation :</strong><br />Associate Manager
                                  </p>
                                  <p><strong>Experience :</strong><br /> Bachelor’s or master’s degree in marketing or a related field
                                  </p>
                                  <p><strong>Location: </strong><br />Noida</p>
                                  <p><strong>Position Summary:</strong></p>
                                  <ul>
                                    <li>Generate revenue through onboarding selling partners on the Portal.</li>
                                    <li>Cultivating long term customer relationships &amp; establishing Strategic Partnership.
                                    </li>
                                    
                                  </ul><br />

                                  <p><strong>Key Responsibilities:</strong></p>
                                  <ul>
                                    <li>Meet Real Estate Channel Partners and onboard them on the newly launched Prop-Fintech
Platform.</li>
                                    <li>Proficiently manage and nurture key accounts with a focus on optimizing online sales and
sales management.
                                    </li>
                                    <li>Maintaining comprehensive customer engagement reports and optimize Customer success
matrix.
                                    </li>
                                    <li>Successfully implementing cross-selling and upselling strategies to enhance client
relationships and maximize revenue by offering relevant products and services available on
platform.
                                    </li>
                                    <li>DevActing as a trusted advertising and marketing consultant for property dealers and channel
partners, providing valuable insights and tailored solutions to meet their unique needs.
                                    </li>
                                    <li>Demonstrating exceptional sales performance by generating revenue from key accounts,
consistently achieving set targets and driving business growth.
                                    </li>
                                    <li>Prioritizing customer relationship management and client servicing, ensuring high levels of
satisfaction and long-term loyalty.
                                    </li>
                                    <li>Pioneering new business development initiatives within the assigned territory, identifying, and
capitalizing on emerging opportunities to expand market presence.
                                    </li>
                                   
                                  </ul><br />

                                  <p><strong>Candidate Profile:</strong></p>
                                  <p>Bachelor’s or master’s degree in marketing or a related field.
                                  </p>
                                  <p>4-6 yrs working experience in ad sales/ digital space/channel partner sales/ Edtech sales.</p>
                                  <p>Well spoken &amp; Proficient in MS office</p>

                                  <p><strong> What we offer:</strong></p>
                                  <p>Chance to be a part of developing India’s first Prop-Fin Tech Platform, Exciting and high-energy
environment, collaborating with some of the sharpest minds in the industry &amp; competitive perks,
moreover it is chance to create your own team while the product scales in multiple territories.
BlackOpal values its highly talented employees and offers the ideal climate for innovative,
motivated, and proactive individuals with diverse backgrounds.
                                  </p>
                                  <p>Please check our website <a href='www.blackopalgroup.in'>www.blackopalgroup.in</a></p>


                                  <p><strong> About BlackOpal :</strong></p>
                                  <p>BlackOpal is an upcoming Real estate Marketing and Consulting Firm. It provides end to end
                                    solutions to Real estate Companies and Financial institutions to maximize the profits and get the
                                    best out of the current situation. It looks at providing sales, CRM and construction solutions to
                                    companies.
                                    The team comprises of highly experienced professionals and our systems and SOP are designed to
                                    create complete transparency in all transactions.
                                  </p>



                                  <span>Please find us at:</span>
                                  <div className="findus">


                                    Interested candidates Kindly share your cv on <a href="mailto:hr@blackopalgroup.in">hr@blackopalgroup.in</a>
                                    for more details contact 0120-5109230
                                    <div style={{ display: 'flex', marginLeft: '-16', marginTop: '5', justifyContent: 'space-around' }} className='car'>

                                      <a href="https://www.facebook.com/Blackopal-Group-105304038115767/?ref=pages_you_manage" target="_blank"><span><i className="fa fa-facebook" /></span></a>

                                      <a href="https://www.linkedin.com/company/blackopal-group/" target="_blank"><span><i className="fa fa-linkedin" /></span></a>


                                      <a href="https://www.instagram.com/blackopalgroup/?igshid=YmMyMTA2M2Y=" target="_blank"><span><i className="fa fa-instagram" /></span></a>


                                      <a href="https://www.youtube.com/channel/UCQAni1Yzdw2FcYBItc7tc8g" target="_blank"><span><i className="fa fa-youtube" /></span></a>

                                    </div>
                                  </div>

                                  <div className="applybtnBox">
                                    {/* <button className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">Apply
                                Now</button> */}
                                    <button className="btn btn-primary" ><a href="mailto:hr@blackopalgroup.in"> <span style={{ color: "#fff" }}>Apply
                                      Now</span></a></button>
                                    <div className="follow ">


                                    </div>
                                  </div>

                                </div>
                              </div>
                            </div>
                          </div>




                          <div className="accordion-item">
                            <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
                                <div className="open-position">Dev Ops Engineer <div className="city" style={{ marginTop: '-9px' }}>Noida</div>
                                </div>
                              </button>
                            </h2>
                            <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse " aria-labelledby="panelsStayOpen-headingTwo" style={{ border: '1px solid #ebe7e7' }}>
                              <div className="accordion-body">
                                <div className="desiredCandidate">
                                  <p><strong>Designation :</strong><br /> Dev Ops Engineer
                                  </p>
                                  <p><strong>Experience :</strong><br /> 2 years of experience
                                    in platform-based product programming
                                  </p>
                                  <p><strong>Location: </strong><br />Noida</p>
                                  <p><strong>Roles and Responsibilities:</strong></p>
                                  <ul>
                                    <li>working on ways to automate and improve development
                                      and release processes.</li>
                                    <li>Responsible for the design, creation, configuration,
                                      and delivery of cloud infrastructure environments
                                      using automation best practices and a proactive
                                      strategy.
                                    </li>
                                    <li>Responsible for providing technology assessments in
                                      support of automation and migration to cloud.</li>
                                    <li>Building automated processes using Devops code
                                      languages</li>
                                  </ul><br />
                                  <p><strong>Desired Candidate Profile:</strong></p>
                                  <ul>
                                    <li>Ability to work independently combined with
                                      excellent interpersonal skills.</li>
                                    <li>Implementing various development, testing,
                                      automation tools, and IT infrastructure.
                                    </li>
                                    <li>Defining and setting development, test, release,
                                      update, and support processes for DevOps operation.
                                    </li>
                                    <li>Troubleshooting techniques and fixing the code bugs.
                                    </li>
                                    <li>Team Management.
                                    </li>
                                    <li>Selecting and deploying appropriate CI/CD tools.
                                    </li>
                                  </ul><br />
                                  <p><strong> About BlackOpal :</strong></p>
                                  <p><strong>Black Opal Financial Services Private Limited (BOFS)</strong> – A sector agnostic NBFC engaged in the business of providing flexible credit solutions for acquisition, last mile financing, inventory funding etc. to SMEs and large corporates.
                                    Our financing solutions include:
                                    Corporate Solutions:
                                  </p>
                                  <p>1.Working Capital solutions: We offer short term financial solutions to our corporate partners to meet their cash flow mismatches and support day to day business operations without any hassles </p>
                                  <p>2.Value Chain Financing: We offer sales invoice and purchase invoice discounting products to our corporate partners for vendor financing and supplementing cash flow requirements, reducing financing cost and improving profitability &amp; growth liquidity</p>
                                  <p>3.Term Capital solutions: We offer tailored financing solutions to meet the objectives to support expansions, buyouts and diversifications through customized term loans, inter-corporate deposits, subscription to debt instruments etc. We also offer promoter funding secured against property, pledge of marketable securities or fixed deposits, guarantee of corporate entity, owner or promoter among others
                                  </p>
                                  <p>4.Invoice Discounting for our Agents and Broker Network: In order to free up liquidity at the end of our agent and broker network, we offer invoice discounting for invoices raised on our builder partners with whom we have signed MoUs</p>
                                  <p><strong>Black Opal Consultants Private Limited (BOC) </strong>– BOC provides fully integrated asset management services for real estate projects. Currently BOC has AUM of 2.5 MM sq.ft. (valued at INR 1.75 Bn) managed through a strong in house team of 40+ professionals across 6 projects. The distinct USP of BOC is its ability to enter at every stage of the Project Lifecycle across Sales &amp; Marketing, CRM, Construction, Design &amp; Financial Management
                                  </p>
                                  <p> <strong> Black Opal Technologies Private Limited (BOT)</strong> – BOT is building the first Prop-Fintech platform to bridge the gaps in the customer lifecycle while buying, selling and financing of real estate sector</p>
                                  <span>Please find us at:</span>
                                  <div className="findus">


                                    Interested candidates Kindly share your cv on <a href="mailto:hr@blackopalgroup.in">hr@blackopalgroup.in</a>
                                    for more details contact 0120-5109230
                                    <div style={{ display: 'flex', marginLeft: '-16', marginTop: '5', justifyContent: 'space-around' }} className='car'>

                                      <a href="https://www.facebook.com/Blackopal-Group-105304038115767/?ref=pages_you_manage" target="_blank"><span><i className="fa fa-facebook" /></span></a>

                                      <a href="https://www.linkedin.com/company/blackopal-group/" target="_blank"><span><i className="fa fa-linkedin" /></span></a>


                                      <a href="https://www.instagram.com/blackopalgroup/?igshid=YmMyMTA2M2Y=" target="_blank"><span><i className="fa fa-instagram" /></span></a>


                                      <a href="https://www.youtube.com/channel/UCQAni1Yzdw2FcYBItc7tc8g" target="_blank"><span><i className="fa fa-youtube" /></span></a>

                                    </div>
                                  </div>
                                  <div className="applybtnBox">
                                    {/* <button className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">Apply
                                Now</button> */}
                                    <button className="btn btn-primary" ><a href="mailto:hr@blackopalgroup.in"> <span style={{ color: "#fff" }}>Apply
                                      Now</span></a></button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="false" aria-controls="panelsStayOpen-collapseOne">
                                <div className="open-position">Growth Operations Management Trainee   <div className="city" style={{ marginTop: '-9px' }}>Noida</div>
                                </div>
                              </button>
                            </h2>
                            <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse " aria-labelledby="panelsStayOpen-headingOne" style={{ border: '1px solid #ebe7e7' }}>
                              <div className="accordion-body">
                                <div className="desiredCandidate">
                                  <p><strong>Designation :</strong><br />Growth Operations Management Trainee
                                  </p>
                                  <p><strong> Position Type :</strong><br />Full-Time
                                  </p>
                                  <p><strong>Experience :</strong><br /> 0-2 years of experience in customer care and client queries (Freshers may apply)
                                  </p>
                                  <p><strong>Location: </strong><br />Noida</p>
                                  <p><strong>Position Summary:</strong></p>
                                  <ul>
                                    <li>Junior Associate (Customer Handling) is required to understand the market and find affordable

solution to collect residential property data from open sources. Associates are required to do cold-
calling in order to comprehend customer challenges and acquire project level data. Associate may

also provide valuable insight for our prop-tech platform.
                                    </li>
                                  </ul><br />
                                  <p><strong>Requirements:</strong></p>
                                  <ul>
                                    <li>A minimum of Bachelor’s degree from renowned university.</li>
                                    <li>0-2 years of experience in customer care and client queries (Freshers may apply)
                                    </li>
                                    <li>Handling customer queries and solving their challenges with an app-based platform.</li>
                                    <li>Research and dig data from publicly available real estate portals/website to post internal data sheet</li>
                                    <li>Establishing Constant communication with channel partners to understand real time market updates</li>
                                    <li>Understanding of real estate market and multiple data sources</li>
                                    <li>Hands-on experience with Microsoft product especially MS Excel and Google spreadsheet.</li>
                                  </ul><br />
                                  <p><strong>Good to have:</strong></p>
                                  <p>Willingness to commit a minimum of 2 years with the company.</p>
                                  <p>Excellent written and verbal communication skills</p>
                                  <p>Well-spoken and keen to learn about real estate industry</p>
                                  <p>Comfortable working with deadlines</p>
                                  <p>Data oriented approach and problem-solving mindset</p>

                                  
                                  <p><strong>What we offer:</strong></p>
                                  <p>Chance to be a part of developing India’s first Prop-Fin Tech Platform, Exciting and high-energy environment,
collaborating with some of the sharpest minds in the industry & competitive perks, moreover it is chance to create
your own team while the product scales in multiple territories.
                                  </p>

                                  <p><strong>What is the Problem Statement?</strong></p>
                                  <p>The current experience of any property Buyer, Owner, or Renter is shattered, biased, and, to a large extent,
exhausted by the loss of privacy. The current market solutions do not contribute to the resolution of the
aforementioned factual issues because they merely serve as a listing platform that generates leads for
Builders/Developers. We believe that instead of going through the hassle of all of the above bottlenecks, property
buyers should have a curated, simplified, tech-enabled, and financially aided experience.
                                  </p>

                                  <p><strong>How Blackopal Technologies is trying to bridge the Gap:</strong></p>
                                  <p>We are building India's first Prop-Fin Tech Platform, it is a platform as a service that will address the 3 trillion market
of the Real Estate Ecosystem. This platform is targeted to empower customers throughout their property-buying
journey till the financial closure (offering financial products on the platform itself) of the unit. This platform shall
create opportunities to connect Real Estate Agents, Customers & Customer Champions in a manner where their
privacy is maintained. This product has multiple unique USPs which we believe shall help the customers find their
dream homes and at the same time have the potential to scale into a standalone ecosystem that attracts & retains
all Real Estate Stakeholders under a single umbrella.
We will solve the existing problems by creating a tech platform that allows property buyers to experience a curated
ecosystem using tech, AI, and machine learning. Users can connect anonymously to verified stakeholders, sales
representatives, owners, tenants, and so on through the platform. Users can interact with the location, learn about
the market, discover the price, and learn about the visibility of a product that must be delivered within a certain time
frame. Furthermore, this platform will boost property buyers' confidence like never before.
                                  </p>
                                 
                                  <span>Please find us at:</span>
                                  <div className="findus">


                                    Interested candidates Kindly share your cv on <a href="mailto:hr@blackopalgroup.in">hr@blackopalgroup.in</a>
                                    for more details contact 0120-5109230
                                    <div style={{ display: 'flex', marginLeft: '-16', marginTop: '5', justifyContent: 'space-around' }} className='car'>

                                      <a href="https://www.facebook.com/Blackopal-Group-105304038115767/?ref=pages_you_manage" target="_blank"><span><i className="fa fa-facebook" /></span></a>

                                      <a href="https://www.linkedin.com/company/blackopal-group/" target="_blank"><span><i className="fa fa-linkedin" /></span></a>


                                      <a href="https://www.instagram.com/blackopalgroup/?igshid=YmMyMTA2M2Y=" target="_blank"><span><i className="fa fa-instagram" /></span></a>


                                      <a href="https://www.youtube.com/channel/UCQAni1Yzdw2FcYBItc7tc8g" target="_blank"><span><i className="fa fa-youtube" /></span></a>

                                    </div>
                                  </div>
                                  <div className="applybtnBox">
                                    {/* <button className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">Apply
                                Now</button> */}
                                    <button className="btn btn-primary" ><a href="mailto:hr@blackopalgroup.in"> <span style={{ color: "#fff" }}>Apply
                                      Now</span></a></button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <input type="radio" name="tabs" id="tabthree" />
                  <label htmlFor="tabthree">Advisory Services</label>
                  <div className="tab">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="false" aria-controls="panelsStayOpen-collapseOne">
                          <div className="open-position">Assistant Manager Sales<div className="city" style={{ marginTop: '-9px' }}>Noida</div>
                          </div>
                        </button>
                      </h2>
                      <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingOne" style={{ border: '1px solid #ebe7e7' }}>
                        <div className="accordion-body">
                          <div className="desiredCandidate">
                            <p><strong>Designation :</strong><br />ASSISTANT MANAGER SALES
                            </p>
                            <p><strong>Experience :</strong><br /> 4-8 years of real
                              estate
                              sales </p>
                            <p><strong>Location: </strong><br />Noida</p>
                            <p><strong>Roles and Responsibilities:</strong></p>
                            <ul>
                              <li>Responsible for sales and marketing of the residential site
                                through all verticals.</li>
                              <li>Following The leads, negotiating commercial terms &amp; closing of
                                the sale.
                              </li>
                              <li>Network with corporate, HNI &amp; other clients for project
                                marketing &amp; sales.</li>
                              <li>Planning and implementing strategies for new project launch
                                mainly project marketing &amp;
                                sales planning</li>
                              <li>Establishing relationship with all distribution partners
                              </li>
                              <li>Inputs on design, cost comparisons, feedback on vendor selection
                                for top management
                                and coordination and planning of the entire exercise.</li>
                              <li>Responsible for post sales cycle and customer satisfaction
                              </li>
                              <li>Creating and sustaining a dynamic environment that fosters the
                                development
                                opportunities and motivates the high performance amongst the
                                team members</li>
                              <li>Intermediate negotiation processes, consult clients on market
                                conditions, prices,
                                mortgages, legal requirements and related matters, ensuring a
                                fair and honest dealing</li>
                            </ul><br />
                            <p><strong>Position Summary: :</strong></p>
                            <p>The AM sales will work towards achieving the sales target month on
                              month.</p>
                            <p>Develop new markets and channel partners, brand promotion, new
                              product launch, accelerating
                              growth &amp; thereon achieving desired sales goals</p>
                            <p>Activating Internal Verticals Loyalty, Referrals and Channel Partners
                              to generate regular business.</p>
                            <p>Keeping abreast of market trends to obtain intelligence reports
                              regarding competition and
                              facilitating the formulation of strategies to counter competition.
                            </p>
                            <p>Developing pre-launch and after sales strategies and processes to
                              maintain cordial relations with
                              clients.</p>
                            <p>Seeking referrals and networking to grow your own
                              suite of clients</p>
                            <p><strong> About BlackOpal :</strong></p>
                            <p><strong>Black Opal Financial Services Private Limited (BOFS)</strong> – A sector agnostic NBFC engaged in the business of providing flexible credit solutions for acquisition, last mile financing, inventory funding etc. to SMEs and large corporates.
                              Our financing solutions include:
                              Corporate Solutions:
                            </p>
                            <p>1.Working Capital solutions: We offer short term financial solutions to our corporate partners to meet their cash flow mismatches and support day to day business operations without any hassles </p>
                            <p>2.Value Chain Financing: We offer sales invoice and purchase invoice discounting products to our corporate partners for vendor financing and supplementing cash flow requirements, reducing financing cost and improving profitability &amp; growth liquidity</p>
                            <p>3.Term Capital solutions: We offer tailored financing solutions to meet the objectives to support expansions, buyouts and diversifications through customized term loans, inter-corporate deposits, subscription to debt instruments etc. We also offer promoter funding secured against property, pledge of marketable securities or fixed deposits, guarantee of corporate entity, owner or promoter among others
                            </p>
                            <p>4.Invoice Discounting for our Agents and Broker Network: In order to free up liquidity at the end of our agent and broker network, we offer invoice discounting for invoices raised on our builder partners with whom we have signed MoUs</p>
                            <p><strong>Black Opal Consultants Private Limited (BOC) </strong>– BOC provides fully integrated asset management services for real estate projects. Currently BOC has AUM of 2.5 MM sq.ft. (valued at INR 1.75 Bn) managed through a strong in house team of 40+ professionals across 6 projects. The distinct USP of BOC is its ability to enter at every stage of the Project Lifecycle across Sales &amp; Marketing, CRM, Construction, Design &amp; Financial Management
                            </p>
                            <p> <strong> Black Opal Technologies Private Limited (BOT)</strong> – BOT is building the first Prop-Fintech platform to bridge the gaps in the customer lifecycle while buying, selling and financing of real estate sector</p>
                            <span>Please find us at:</span>
                            <div className="findus">

                              Interested candidates Kindly share your cv on <a href="mailto:hr@blackopalgroup.in">hr@blackopalgroup.in</a>
                              for more details contact 0120-5109230
                              <div style={{ display: 'flex', marginLeft: '-16', marginTop: '5', justifyContent: 'space-around' }} className='car'>

                                <a href="https://www.facebook.com/Blackopal-Group-105304038115767/?ref=pages_you_manage" target="_blank"><span><i className="fa fa-facebook" /></span></a>

                                <a href="https://www.linkedin.com/company/blackopal-group/" target="_blank"><span><i className="fa fa-linkedin" /></span></a>


                                <a href="https://www.instagram.com/blackopalgroup/?igshid=YmMyMTA2M2Y=" target="_blank"><span><i className="fa fa-instagram" /></span></a>


                                <a href="https://www.youtube.com/channel/UCQAni1Yzdw2FcYBItc7tc8g" target="_blank"><span><i className="fa fa-youtube" /></span></a>

                              </div>
                            </div>
                            <div className="applybtnBox">
                              {/* <button className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">Apply
                                Now</button> */}
                              <button className="btn btn-primary" ><a href="mailto:hr@blackopalgroup.in"> <span style={{ color: "#fff" }}>Apply
                                Now</span></a></button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="false" aria-controls="panelsStayOpen-collapseOne">
                          <div className="open-position">Team Leader Sales <div className="city" style={{ marginTop: '-9px' }}>Noida</div>
                          </div>
                        </button>
                      </h2>
                      <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
                        <div className="accordion-body">
                          <div className="desiredCandidate">
                            <p><strong>Designation :</strong><br /> TEAM LEADER - SALES
                            </p>
                            <p><strong>Experience :</strong><br /> 6-10 years of real
                              estate
                              sale </p>
                            <p><strong>Location: </strong><br />Noida</p>
                            <p><strong>Key Responsibilities:</strong></p>
                            <ul>
                              <li>Handling the Sales team, nurturing leads, negotiating commercial
                                terms &amp; closing of the
                                sale.</li>
                              <li>Network with corporate, HNI &amp; other clients for project
                                marketing &amp; sales.
                              </li>
                              <li>Planning and implementing strategies for new project launch
                                mainly project marketing &amp;
                                sales planning</li>
                              <li>Establishing relationship with all distribution partners</li>
                              <li>Design sales strategy, marketing plans, inventory strategy etc.
                              </li>
                              <li>Responsible for post sales cycle and customer satisfaction</li>
                              <li>Creating and sustaining a dynamic environment that fosters the
                                development
                                opportunities and motivates the high performance amongst the
                                team members
                              </li>
                              <li>Formulating strategies &amp; reaching out to unexplored market
                                segments/customer groups
                                for business expansion by strategizing /organizing campaigns and
                                developing grass root
                                sales network</li>
                              <li>Intermediate negotiation processes, consult clients on market
                                conditions, prices,
                                mortgages, legal requirements and related matters, ensuring a
                                fair and honest dealing</li>
                            </ul><br />
                            <p><strong>Minimum Requirements:</strong></p>
                            <ul>
                              <li>MBA with at least 6-10+ year’s relevant experience in sales with
                                a renowned developer or
                                channel partner and handling a team.</li>
                              <li>Good experience in activating channel partners in NCR especially
                                Noida.
                              </li>
                              <li>Knowledge of consumer trends &amp; trade practices.</li>
                              <li>Sales forecasting techniques</li>
                              <li>People management and cross functional projects management
                                skills, leadership skills,
                                strong analytical skills, entrepreneurial drive.
                              </li>
                              <li>Should have proficiency in English – written and spoken.</li>
                              <li>Excellent knowledge of Excel and power point.
                              </li>
                            </ul><br />
                            <p><strong>Position Summary:</strong></p>
                            <ul>
                              <li>The team leader sales will establish the team and work towards
                                achieving the sales target month
                                on month.</li>
                              <li>Develop new markets and channel partners, brand promotion, new
                                product launch, accelerating
                                growth &amp; thereon achieving desired sales goals.
                              </li>
                              <li>Activating Internal Verticals Loyalty, Referrals and Channel
                                Partners to generate regular business..</li>
                              <li>Keeping abreast of market trends to obtain intelligence reports
                                regarding competition and
                                facilitating the formulation of strategies to counter
                                competition..</li>
                              <li>Developing pre-launch and after sales strategies and processes
                                to maintain cordial relations with
                                clients.
                              </li>
                            </ul><br />
                            <p><strong> About BlackOpal :</strong></p>
                            <p><strong>BlackOpal Financial Services Private Limited (BOFS)</strong> – A sector agnostic NBFC engaged in the business of providing flexible credit solutions for acquisition, last mile financing, inventory funding etc. to SMEs and large corporates.
                              Our financing solutions include:
                              Corporate Solutions:
                            </p>
                            <p>1.Working Capital solutions: We offer short term financial solutions to our corporate partners to meet their cash flow mismatches and support day to day business operations without any hassles </p>
                            <p>2.Value Chain Financing: We offer sales invoice and purchase invoice discounting products to our corporate partners for vendor financing and supplementing cash flow requirements, reducing financing cost and improving profitability &amp; growth liquidity</p>
                            <p>3.Term Capital solutions: We offer tailored financing solutions to meet the objectives to support expansions, buyouts and diversifications through customized term loans, inter-corporate deposits, subscription to debt instruments etc. We also offer promoter funding secured against property, pledge of marketable securities or fixed deposits, guarantee of corporate entity, owner or promoter among others
                            </p>
                            <p>4.Invoice Discounting for our Agents and Broker Network: In order to free up liquidity at the end of our agent and broker network, we offer invoice discounting for invoices raised on our builder partners with whom we have signed MoUs</p>
                            <p><strong>Black Opal Consultants Private Limited (BOC) </strong>– BOC provides fully integrated asset management services for real estate projects. Currently BOC has AUM of 2.5 MM sq.ft. (valued at INR 1.75 Bn) managed through a strong in house team of 40+ professionals across 6 projects. The distinct USP of BOC is its ability to enter at every stage of the Project Lifecycle across Sales &amp; Marketing, CRM, Construction, Design &amp; Financial Management
                            </p>
                            <p> <strong> Black Opal Technologies Private Limited (BOT)</strong> – BOT is building the first Prop-Fintech platform to bridge the gaps in the customer lifecycle while buying, selling and financing of real estate sector</p>
                            <span>Please find us at:</span>
                            <div className="findus">

                              Interested candidates Kindly share your cv on <a href="mailto:hr@blackopalgroup.in">hr@blackopalgroup.in</a>
                              for more details contact 87556 02241
                              <div style={{ display: 'flex', marginLeft: '-16', marginTop: '5', justifyContent: 'space-around' }} className='car'>

                                <a href="https://www.facebook.com/Blackopal-Group-105304038115767/?ref=pages_you_manage" target="_blank"><span><i className="fa fa-facebook" /></span></a>

                                <a href="https://www.linkedin.com/company/blackopal-group/" target="_blank"><span><i className="fa fa-linkedin" /></span></a>


                                <a href="https://www.instagram.com/blackopalgroup/?igshid=YmMyMTA2M2Y=" target="_blank"><span><i className="fa fa-instagram" /></span></a>


                                <a href="https://www.youtube.com/channel/UCQAni1Yzdw2FcYBItc7tc8g" target="_blank"><span><i className="fa fa-youtube" /></span></a>

                              </div>
                            </div>
                            <div className="applybtnBox">
                              {/* <button className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">Apply
                                Now</button> */}
                              <button className="btn btn-primary" ><a href="mailto:hr@blackopalgroup.in"> <span style={{ color: "#fff" }}>Apply
                                Now</span></a></button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <input type="radio" name="tabs" id="tabhr" />
                  <label htmlFor="tabhr">Others</label>
                  <div className="tab">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="panelsStayOpen-headinghr">
                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapsehr" aria-expanded="false" aria-controls="panelsStayOpen-collapsehr">
                          <div className="open-position">
                            {/* <div className="city" style={{marginTop: '-9px'}}>Noida</div>  */}
                          </div>
                        </button>
                      </h2>
                      {/* <div id="panelsStayOpen-collapsehr" class="accordion-collapse collapse"
                                  aria-labelledby="panelsStayOpen-headinghr" style="border: 1px solid #ebe7e7;">
                                  <div class="accordion-body">
                                      <div class="desiredCandidate">

                                          <p><strong>Designation :</strong><br>Senior Executive/ AM Human
                                              Resources
                                          </p>

                                          <p><strong>Experience :</strong><br> 2-5 experience in HR</p>

                                          <p><strong>Location: </strong><br>Noida</p>

                                          <p><strong>Roles and Responsibilities:</strong></p>

                                          <ul>
                                              <li>Responsible for sales and marketing of the residential site
                                                  through all verticals.</li>
                                              <li>Following The leads, negotiating commercial terms & closing of
                                                  the sale.
                                              </li>
                                              <li>Network with corporate, HNI & other clients for project
                                                  marketing & sales.</li>
                                              <li>Planning and implementing strategies for new project launch
                                                  mainly project marketing &
                                                  sales planning</li>
                                              <li>Establishing relationship with all distribution partners
                                              </li>
                                              <li>Inputs on design, cost comparisons, feedback on vendor selection
                                                  for top management
                                                  and coordination and planning of the entire exercise.</li>
                                              <li>Responsible for post sales cycle and customer satisfaction
                                              </li>
                                              <li>Creating and sustaining a dynamic environment that fosters the
                                                  development
                                                  opportunities and motivates the high performance amongst the
                                                  team members</li>
                                              <li>Intermediate negotiation processes, consult clients on market
                                                  conditions, prices,
                                                  mortgages, legal requirements and related matters, ensuring a
                                                  fair and honest dealing</li>

                                          </ul><br>

                                          <p><strong>Position Summary: :</strong></p>

                                          <p>The AM sales will work towards achieving the sales target month on
                                              month.</p>

                                          <p>Develop new markets and channel partners, brand promotion, new
                                              product launch, accelerating
                                              growth & thereon achieving desired sales goals</p>

                                          <p>Activating Internal Verticals Loyalty, Referrals and Channel Partners
                                              to generate regular business.</p>

                                          <p>Keeping abreast of market trends to obtain intelligence reports
                                              regarding competition and
                                              facilitating the formulation of strategies to counter competition.
                                          </p>

                                          <p>Developing pre-launch and after sales strategies and processes to
                                              maintain cordial relations with
                                              clients.</p>

                                          <p>Seeking referrals and networking to grow your own
                                              suite of clients</p>


                                              <p><strong> About BlackOpal :</strong></p>

                                              <p><strong>Black Opal Financial Services Private Limited (BOFS)</strong> - A sector agnostic NBFC engaged in the business of providing flexible credit solutions for acquisition, last mile financing, inventory funding etc. to SMEs and large corporates. 
                                                  Our financing solutions include:
                                                  Corporate Solutions:
                                                  
                                              </p>
                                              <p>1.Working Capital solutions: We offer short term financial solutions to our corporate partners to meet their cash flow mismatches and support day to day business operations without any hassles </p>
                                              <p>2.Value Chain Financing: We offer sales invoice and purchase invoice discounting products to our corporate partners for vendor financing and supplementing cash flow requirements, reducing financing cost and improving profitability & growth liquidity</p>
                                              <p>3.Term Capital solutions: We offer tailored financing solutions to meet the objectives to support expansions, buyouts and diversifications through customized term loans, inter-corporate deposits, subscription to debt instruments etc. We also offer promoter funding secured against property, pledge of marketable securities or fixed deposits, guarantee of corporate entity, owner or promoter among others
    
                                              </p>
                                              <p>4.Invoice Discounting for our Agents and Broker Network: In order to free up liquidity at the end of our agent and broker network, we offer invoice discounting for invoices raised on our builder partners with whom we have signed MoUs</p>
                                              <p><strong>Black Opal Consultants Private Limited (BOC) </strong>- BOC provides fully integrated asset management services for real estate projects. Currently BOC has AUM of 2.5 MM sq.ft. (valued at INR 1.75 Bn) managed through a strong in house team of 40+ professionals across 6 projects. The distinct USP of BOC is its ability to enter at every stage of the Project Lifecycle across Sales & Marketing, CRM, Construction, Design & Financial Management
    
                                              </p>
                                              <p> <strong> Black Opal Technologies Private Limited (BOT)</strong> - BOT is building the first Prop-Fintech platform to bridge the gaps in the customer lifecycle while buying, selling and financing of real estate sector</p>
                                          <span>Please find us at:</span>
                                          <div class="findus"><a
                                                  href="https://blackopalgroup.in/">www.blackopalgroup.in</a>
                                              <a
                                                  href="https://www.linkedin.com/company/blackopal-group/">www.linkedin.com/company/blackopal-group</a>
                                              Interested candidates Kindly share your cv on <a
                                                  href="mailto:hr@blackopalgroup.in">hr@blackopalgroup.in</a>
                                              for more details contact 0120-5109230
                                          </div>

                                          <div class="applybtnBox">
                                              <button class="btn btn-primary" data-bs-toggle="modal"
                                                  data-bs-target="#exampleModal">Apply
                                                  Now</button>
                                          </div>
                                      </div>
                                  </div>
                              </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="jop-apply">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="open_heading text-center">
                  <h2 className="positions_text mb-0">
                    Can’t find your dream job?
                  </h2>
                  <div className="job">
                    <div className="col-md-8 offset-md-2">
                      <p>If you don't find an open position for your ideal gig, get in touch with us and
                        we
                        can explore creating one for you! Tell us why you would be a good fit for us and
                        what sort of role you can see yourself in and we will get in touch with you.</p>
                    </div>
                    {/* <div className="btn-jobapply" data-bs-toggle="modal" data-bs-target="#exampleModal">
                      SUBMIT RESUME
                    </div> */}
                    <div className="btn-jobapply" >
                      <a href='mailto:hr@blackopalgroup.in'> <span style={{ color: "#fff" }}>SUBMIT RESUME</span></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: 'none' }}>
          <div className="modal-dialog">
            <div className="modal-content modal-sm">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel" />
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
              </div>
              <div className="modal-body">
                <div className="bo-contact contact-style2 bg9 md-pt-70 md-pb-70">
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="  mb-45 md-mb-25 contatheading">
                          Share your profile
                        </div>
                        <div className="contact-wrap">
                          <div className="status">
                          </div>
                          <form method="post" className="form" encType="multipart/form-data">
                            <div className="row">
                              <div className="col-lg-12 col-md-6 col-sm-6 mb-30">
                                <label>Full Name<span style={{ color: 'red' }}>*</span></label>
                                <input type="text" className="from-control" name="name" id="name" placeholder="Enter Your Name" autoComplete="off" required />
                              </div>
                              <div className="col-lg-12 col-md-6 col-sm-6 mb-30">
                                <label> E-Mail<span style={{ color: 'red' }}>*</span></label>
                                <input type="email" className="from-control" name="email" id="email" placeholder="Enter Your Email ID" autoComplete="off" required />
                              </div>
                              <div className="col-lg-12 col-md-6 col-sm-6 mb-30">
                                <label>Phone<span style={{ color: 'red' }}>*</span></label>
                                <input type="num" name="phone" className="from-control" placeholder="Enter Your Phone" autoComplete="off" required />
                              </div>
                              <div className="col-lg-12 col-md-6 col-sm-6 mb-30">
                                <label>About you (optional)<span style={{ color: 'red' }}>*</span></label>
                                <input type="text" name="message" className="from-control" placeholder="Enter Your Message" autoComplete="off" required />
                              </div>
                              <div className="col-lg-12 col-md-6 col-sm-6 mb-30">
                                <div className="form-group">
                                  <label>Upload CV/Resume<span style={{ color: 'red' }}>*</span></label>
                                  <input type="file" name="attachment" className="form-control" multiple />
                                  <p style={{ fontSize: 12, marginTop: 5 }}>Allowed
                                    Type(s): .pdf, .doc, .docx</p>
                                </div>
                              </div>
                            </div>
                            <div className="formGroup">
                              <button className="readon submit" name="submit" value="SUBMIT" type="submit">SUBMIT </button>
                            </div>
                          </form></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>


    </>

  )
}

export default Career;

