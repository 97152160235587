import React from 'react'
import Header from '../components/Header';
import Footer from "../components/Footer";


const Termsofuse = () => {
  return (

    <div className="main-content">

      <Header />

      
      <div className="container">
  <div className="row">
    <div className="col-md-12 col-md-6 wow animate__animated animate__fadeInUp">
      <div className="about-section">
        <h2>Terms of use</h2>
        <p>The use of any product, service or feature (the "Materials") available through the internet websites accessible at blackopalgroup.in (the "Website") by any user of the Website (“User” or "You" or "Your" hereafter) shall be governed by the following terms of use. The following terms and conditions will be deemed to have been accepted by the User on usage of the Website. You are requested to read them carefully before you use the services of this site.
        </p>
        <p>The term User shall refer to the user who is browsing the Website.
        </p>
        <p> This Website is provided by Black Opal Group Limited (hereinafter referred to as "Black Opal Group" ), a company incorporated under the Companies Act, 1956 of India, having its registered office at Ground Floor, Shop No.8, C.S.C., AGCR Enclave, New Delhi, East Delhi, Delhi 110092, India and shall be used for informational purposes only. By using the Website or downloading Materials from the Website, You at this moment agree to abide by the terms and conditions set forth in this Terms of Use. In the event of You not agreeing to these terms and conditions, You are requested by Black Opal Group not to use the Website or download Materials from the Website. If there is a conflict between the Terms of Use and Terms of Use posted for a specific area of the Website, the latter shall have precedence with respect to your use of that area of the Website.</p>
        <p>This Website, including all Materials present (excluding any applicable third party materials), is the property of Black Opal Group, and Black Opal Group retains all rights, title or interest, including all intellectual property laws in such Materials. </p>
        <p> Black Opal Group has business relationships with thousands of customers, suppliers, governments, and others. For convenience and simplicity, words like joint venture, partnership, and partner are used to indicate business relationships involving common activities and interests, and those words may not indicate precise legal relationships.
        </p>
        <h3>LIMITED LICENSE:</h3>   
        <p>This Web site contains proprietary notices and copyright information, the terms of which must be observed and followed. Subject to the terms and conditions set forth in these Terms of Use, Black Opal Group grants You a non-exclusive, non-transferable, limited copyright license to access, and display this Website and the Materials thereon provided you comply with these Terms of Use, and all copyright, trademark, and other proprietary notices remain intact. You agree not to interrupt or attempt to interrupt the website’s operation in any manner.. You shall not modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell any information, software, products or services obtained from this Website. Except for the limited permission in the preceding paragraph, Black Opal Group does not grant you any express or implied rights or licenses under any patents, trademarks, copyrights, or other proprietary or intellectual property rights. You may not mirror any of the content from this site on another Web site or in any other media. Any software and other materials that are made available for downloading, access, or other use from this site with their own license terms will be governed by such terms, conditions, and notices. Your failure to comply with such terms or any of the terms on this site will result in automatic termination of any rights granted to you, without prior notice, and you must immediately destroy all copies of downloaded materials in your possession, custody or control.</p>
        <h3>BLOGS:</h3>   
        <p>This is to inform and clarify that individuals (including but not limited to employees of Black Opal Group and referred to as “Individuals” hereafter) may contribute articles and opinions on this Website entirely at the sole discretion of Black Opal Group, in the form of “blogs,” as such term is generally understood. You hereby acknowledge and agree that these blogs constitute the opinion of the Individuals in their personal capacity and may not represent official positions of Black Opal Group in any manner. Black Opal Group retains all copyright to these blogs and grants the same license as mentioned under the section "Limited License" hereinabove.
          .</p>
        <p>You may be permitted to post comments and feedback to these blogs. By doing so, you expressly agree and acknowledge to abide by the following:
        </p>
        <p>You shall not defame, disrepute, abuse, harass, or threaten Black Opal Group or any third party, or otherwise violate the legal rights of Black Opal Group or any third party.</p>
        <p> You shall not contribute any content or take any action that may in any manner adversely affect the reputation of Black Opal Group or that is otherwise detrimental to Black Opal Group.</p>
        <p> You shall not in any manner publish or post any inappropriate, defamatory, infringing, obscene, racist, terrorist, politically slanted, indecent, or unlawful topic, name, material, or information.</p>
        <p> You shall not use profane and objectionable language or abbreviations. You shall not use any character(s) as a substitute for objectionable language.</p>
        <p> You shall not in any manner reveal confidential or proprietary information of any third party. Specifically, you shall not post any material for which you do not have the requisite and applicable right(s) under the law.</p>
        <p> You shall not conduct any contests or publish or propagate any forwards.
        </p><p>You shall not defame, abuse, harass, stalk, threaten, or otherwise violate the legal rights (such as rights of privacy and publicity but not limited to the foregoing) of any other party, including Black Opal Group.</p>
        <p> You shall not publish, post, upload, distribute, or disseminate any inappropriate, profane, defamatory, obscene, indecent, or unlawful topic, name, material, or information.</p>
        <p> You shall not upload or otherwise make available files that contain images, photographs, software, or other material protected by intellectual property laws, including, by way of example and not as a limitation, copyright or trademark laws (or by rights of privacy or publicity) unless you own or control the rights thereto or have received all necessary consent to do the same.</p>
        <p>You shall not upload files that contain viruses, trojan horses, worms, time bombs, cancelbots, corrupted files, or any other similar software or programs that may damage the operation of another's computer or the property of another.</p>
        <p> You shall not advertise or offer to sell or buy any goods or services for any business purpose.</p>
        <p>You shall not download any file posted that you know, or reasonably should know, cannot be legally reproduced, displayed, performed, and/or distributed in such a manner.</p>
        <p> You shall not falsify or delete any copyright management information, such as author attributions, legal or other proper notices, proprietary designations or labels of the origin or source of software or other material contained in a file that is uploaded.
        </p><p> You shall not create a false identity for the purpose of misleading others.</p>
        <p>  You shall not in any way deface or vandalize this Website or prevent or restrict others from using this Website.</p>
        <p> You shall indemnify and hold harmless Black Opal Group from any claims and loss incurred by Black Opal Group as a result of your violation of these Terms of Use.</p>
        <p>  You acknowledge that Black Opal Group may, at its sole discretion, monitor, remove, or edit any content that you contribute. Black Opal Group may also pursue remedies available to
          it under the law for any violation of these terms and conditions.</p>
        <p>You shall ensure that any personal data of other individuals is shared by you only after seeking consent from the person(s) to whom the personal data belongs.</p> 
        <h3> THIRD-PARTY CONTENT:</h3> 
        <p>The Website makes information of third parties available, including articles, analyst reports, news reports, tools to facilitate calculation, company information, and data about financial markets, including any regulatory authority and other financial markets, and other data from external sources (the "Third Party Content"). You acknowledge and agree that the Third Party Content is not created or endorsed by Black Opal Group.
        </p>  
        <p>The Website may provide links to websites and access to content, products, and services from third parties, including users, advertisers, affiliates, and sponsors of the Website, i.e., Third Party Content. You agree that Black Opal Group is not responsible for the availability of, and content provided on, third-party websites. The User is requested to peruse the policies posted by other websites regarding privacy and other topics before use. Any personal data processing by such third parties shall be governed by their respective privacy policies.
          Black Opal Group is not responsible for Third Party Content accessible through the Website, including opinions, advice, statements, and advertisements, and the User shall bear all risks associated with the use of such content, including any intellectual property infringement claim by third parties. Black Opal Group is not responsible for any loss or damage of any sort that the User may incur from dealing with any third-party or Third Party Content.
        </p>
        <p>Confidential information</p>
        <p> Black Opal Group does not want to receive confidential or proprietary information from you through our website. Please note that any information or material sent to Black Opal Group will be deemed NOT to be confidential. By sending Black Opal Group any information or material, you grant Black Opal Group an unrestricted, irrevocable license to copy, reproduce, publish, upload, post, transmit, distribute, publicly display, perform, modify, create derivative works from, and otherwise freely use those materials or information. You also agree that Black Opal Group is free to use any ideas, concepts, know-how, or techniques that you send us for any purpose. However, 
          we will not release your name or otherwise publicize the fact that you submitted materials or other information 
          to us unless: (a) we obtain your permission to use your name; or (b) we first notify 
          you that the materials or other information you submit to a particular part of this site will 
          be published or otherwise used with your name on it; or (c) we are required to do so by law. 
          Personally identifiable information that you submit to Black Opal Group for the purpose of receiving 
          products or services will be handled in accordance with our privacy policies. Please refer 
          to Black Opal Group's Privacy Statement for more information regarding our privacy practices.</p>
        <h3> NO WARRANTIES: </h3>
        <p> This website, the information and materials on the site, and any software made available on the Website, are provided "as is" without any representation or warranty, express or implied, of any kind, including, but not limited to, warranties of merchantability, non-infringement, or fitness for any particular purpose. There is no warranty of any kind, express or implied, regarding third-party content. In spite of Black Opal Group's best endeavours, there is no warranty on behalf of Black Opal Group that this Website will be free of any computer viruses. Black Opal Group shall have no responsibility for any damage to the User’s computer system or loss of data that results from the download of any content, materials, or information from the Website. Some jurisdictions do not allow for the exclusion of implied warranties, so the above exclusions may not apply to you. Black Opal Group makes no warranty that: (a) the Website will meet your requirements; (b) the Website will be available on an uninterrupted, timely, secure, or error-free basis; (c) the results that may be obtained from the use of the website or any services offered through the Website will be accurate or reliable.</p>  
        <h3> LIMITATION OF DAMAGES:</h3>
        <p>To the fullest extent permitted by applicable law, in no event will black opal group be liable to any party for any direct, indirect, incidental, special, exemplary, or consequential damages of any type whatsoever related to or arising from this website or any use of this web site, or of any site or resource linked to, referenced, or accessed through this web site, or in the products, any claim attributable to errors, omissions, or other inaccuracies in the product or interpretations thereof or for the use or downloading of, or access to, any materials, information, products, or services, including, without limitation, any lost profits, business interruption, lost savings, or loss of programs or other data, even if black opal group is expressly advised of the possibility of such damages. this exclusion and waiver of liability applies to all causes of action, whether based on contract, warranty, tort, or any other legal theories.</p>
        <h3> DISCLAIMER:</h3>
        <p>The website may contain inaccuracies and typographical and clerical errors. Black Opal Group expressly disclaims any obligation(s) to update this website or any of the materials on this website. Black Opal Group does not warrant the accuracy, adequacy, or completeness of the materials or the reliability of any advice, opinion, statement, or other information displayed or distributed through the Website. You acknowledge that any reliance on any such opinion, advice, statement, memorandum, or information shall be at your sole risk. Black Opal Group reserves the right, in its sole discretion, to correct any errors or omissions in any portion of the Website. Black Opal Group may make any other changes to the Website, the materials, and the products, programs, services, or prices (if any) described on the Website at any time without notice. This Website is for informational purposes only and should not be construed as technical advice of any manner. If any term in this Terms of Use is found by competent judicial authority to be unenforceable in any respect, the validity of the remainder of this Terms of Use will be unaffected, provided that such unenforceability does not materially affect the parties’ rights under this Terms of Use.
        </p><h3> LAWFUL AND/OR PROHIBITED USE OF THE WEBSITE:</h3>
        <p>  As a condition of Your use of the Website, You shall not use the Website for any purpose(s) that is unlawful or prohibited by the Terms of Use. You shall not use the Website in any manner that could damage, disable, overburden, or impair any Black Opal Group server, or the network(s) connected to any Black Opal Group server, or interfere with any other party's use and enjoyment of any services associated with the Website. You shall not attempt to gain unauthorized access to any section of the Website, other accounts, computer systems, or networks connected to any Black Opal Group server or to any of the services associated with the Website, through hacking, password mining, or any other means. You shall not obtain or attempt to obtain any Materials or information through any means not intentionally made available through the Website.</p>
        <h3>INDEMNITY:</h3>
        <p>You agree to indemnify and hold harmless Black Opal Group, its subsidiaries, and affiliates from any claim, cost, expense, judgment, or other loss relating to Your use or misuse of the content and services provided through this Website in any manner, including, without limitation, any action You take which is in violation of the terms and conditions of these Terms of Use and against any applicable law. This provision shall survive any termination of User access by Black Opal Group at any point in time.</p>
        <h3> CHANGES:
          :</h3>
        <p>Black Opal Group reserves the rights, at its sole discretion, to change, modify, add, or remove any portion of these Terms of Use in whole or in part at any time. Changes in these Terms of Use will be effective immediately when notice of such change is posted. Your continued use of the Website after any changes to these Terms of Use are posted will be considered acceptance of those changes. Black Opal Group may terminate, change, suspend, or discontinue any aspect of the Website, including the availability of any feature(s) of the Website, at any time. Black Opal Group may also impose limits on certain features and services or restrict Your access to certain sections or all of the Website without notice or liability. You hereby acknowledge and agree that Black Opal Group may terminate the authorization, rights, and license given above at any point in time at its own sole discretion, and upon such termination, You shall immediately destroy all Materials.</p>
        <h3>INTERNATIONAL USERS AND CHOICE OF LAW:
        </h3>
        <p>This Site is controlled, operated, and administered by Black Opal Group from its offices within India. Black Opal Group makes no representation that Materials on this Website are appropriate or available for use at any other location(s) outside India. Any access to this Website from territories where their contents are illegal is prohibited. You may not use the Website or export the Materials in violation of any applicable export laws and regulations. If You access this Website from a location outside India, You are responsible for compliance with all local laws.</p>
        <p> These Terms of Use shall be governed by the laws of India, without giving effect to its conflict of laws provisions. You agree that the appropriate court(s) in Delhi, India, will have the exclusive jurisdiction to resolve all disputes arising under these Terms of Use, and You hereby consent to personal jurisdiction in such forum.</p>
        <p> These Terms of Use constitute the entire agreement between Black Opal Group and You with respect to Your use of the Website. Any claim You may have with respect to Your use of the Website must be commenced within one (1) year of the cause of action. If any provision(s) of these Terms of Use is held by a court of competent jurisdiction to be contrary to law, then such provision(s) shall be severed from these Terms of Use, and the other remaining provisions of these Terms of Use shall remain in full force and effect.
        </p>
      </div>
    </div>
  </div>
</div>

      <Footer />
    </div>

  )
}

export default Termsofuse;