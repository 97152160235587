
import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Footer from "../components/Footer";
// import ReactPlayer from 'react-player';
import { Helmet } from 'react-helmet';


 const TechnologyServices = () => {

  const pageTitle = "Technology Services";
    const text = ["Belonging", "Unity", "Caring", "Togetherness", "Welcoming", "Strength", "Love"];
    const [counter, setCounter] = useState(0);
    const [currentText, setCurrentText] = useState(text[counter]);
  
    useEffect(() => {
      const interval = setInterval(() => {
        setCounter(prevCounter => (prevCounter + 1) % text.length);
      }, 1500);
  
      return () => {
        clearInterval(interval);
      };
    }, []);
  
    useEffect(() => {
      setCurrentText(text[counter]);
    }, [counter]);


   

  return (

   <>
   <Helmet>
        <title>{pageTitle}</title>
        
      </Helmet> 
   <div class="main-content">
   <Header/>
    <section className="about">
    <div className="container-fluid animate__animated animate__fadeInUp animate__800ms">
      <div className="row align-items-center">
        <div className="col-xl-6 col-lg-6">

          <div className="mb-4 mb-lg-0 video-wrapper header-img  border-radius-bottom">

         <video
           className='react-player'
        src='/Video/Technology-Page.mp4'
        autoPlay={true}
preload={'auto'}
muted={true}
// controls
loop={true}
   width='100%'
            height='100%'
      /> 
    
 

         

          </div>
        </div>


        <div className="col-xl-6 col-lg-6 ">
          <div className="sec-title2 mt-30 about-text">
            <div className="about-sub-text">
              <div className="technology">
                <h2>Home Means </h2>
               
                <div id="changeText">{currentText}</div>
              </div>
            </div>
            <p className="sub-text mb-0">At Blackopal, we are at the forefront of revolutionizing the real estate sector with our fully integrated and comprehensive ecosystem. We go beyond traditional property search platforms by seamlessly integrating People, Prospects, and Growth Partners. We offer the power of data and advanced algorithms to help you make informed decisions. Additionally, we provide access to credit, ensuring that financing is readily available to facilitate your real estate aspirations.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="services" id="offering" style={{background: '#fbfbfb'}}>
    <div className="container">
      <div className="row">
        <div className="col-lg-6 ">
          <div className="main-title text-left">
            <h2 style={{color: '#f26624'}}>TECH FOR GOOD</h2>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 col-lg-4 col-xl-4">
         
            <div className="for_blog feat_property animate__animated animate__fadeInUp animate__delay-2s">
              <div className="thumb2 overlay borderhome">
                <h3 className="info-title">
                  Ecosystem,<br />Not
                  Just A Platform                             </h3>
                <img className="img-whp " src="assets/img/eassy.jpg" />
              </div>
              <div className="details">
                <div className="tc_content">
                  <p className="text-thm">Our platform serves as a hub, empowering property owners, buyers, and agents with a seamless and efficient experience. It offers single-window view, where property owners can manage all documents. Buyers receive personalized recommendations based on their preferences, while agents benefit from streamlined transaction management tools. This interconnected ecosystem creates an infinite loop of efficiency, fostering a thriving and collaborative real estate community.
                  </p>
                </div>
              </div>
            </div>
       
        </div>
        <div className="col-md-6 col-lg-4 col-xl-4">
          
            <div className="for_blog feat_property animate__animated animate__fadeInUp animate__delay-2s">
              <div className="thumb2 overlay borderhome">
                <h3 className="info-title">Authenticated Participation</h3>
                <img className="img-whp " src="assets/img/auth.jpg" />
              </div>
              <div className="details">
                <div className="tc_content">
                  <p className="text-thm">
                    The backbone of our tech interface lies user authentication &amp; privacy. We ensure that only committed and genuine stakeholders participate on the platform. By verifying users, we promote transparency, eliminate ambiguity, and reduce transactional fatigue. This authentication process fosters a trustworthy environment for all stakeholders involved. With authenticated participation, we create a secure and reliable platform that instills confidence in our users.
                  </p>
                </div>
              </div>
            </div>
        </div>
        <div className="col-md-6 col-lg-4 col-xl-4">
         
            <div className="for_blog feat_property animate__animated animate__fadeInUp animate__delay-2s">
              <div className="thumb2 overlay borderhome">
                <h3 className="info-title">Advance Scoring Algorithm</h3>
                <img className="img-whp" src="assets/img/rat.jpg" />
              </div>
              <div className="details">
                <div className="tc_content">
                  <p className="text-thm">Our platform utilizes advanced technology to provide deep insights across products and services. With an advanced scoring algorithm, we facilitate decision-making for customers and agents. This mechanism assigns scores to projects, buyers, sellers, agents, owners, and tenants, fostering transparency and accountability. By creating a common ground where stakeholders can rate each other, our platform ensures complete transparency in the real estate ecosystem.
                  </p>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div></section>
  <div className="rs-about  pb-100 md-pt-70 md-pb-70">
    <div className="container-fluid">
      <div className="row y-middle">
        <div className="col-lg-6 pl-220 md-pr-15 md-mb-50 animate__animated animate__fadeInLeft animate__delay-2s">
          <div className="sec-title2 mb-30 abouttop">
            <h2 className="title mb-23">Easy Access <br /> to capital</h2>
            <div className="about-img eco-web">
              <img src="assets/img/eassyaccess.jpg" className="borderbottom" alt="images" />
            </div>
            <p className="mb-0 aboutp">Through authenticated participation, verified stakeholders gain access to pre-qualified credit. Our tech-enabled lending platform, supported by our in-house NBFC, efficiently services the credit demand generated within our ecosystem. This integration allows us to scale our product effectively, meeting the financing needs of our stakeholders.
            </p>
          </div>
        </div>
        <div className="col-lg-6  animate__animated animate__fadeInRight animate__delay-3s pos">
          <div className="about-img eco-img">
            <img src="assets/img/eassyaccess.jpg" className="borderbottom" alt="images" />
          </div>
        </div>
      </div>
    </div>
  </div>
<Footer/>
</div>


   </>



  )
}

export default TechnologyServices
