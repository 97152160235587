import React from 'react'
import Header from '../components/Header';
import Footer from "../components/Footer";
import Videoplayer from "../Pages/Videoplayer";
import { Helmet } from 'react-helmet';

const Media = () => {
    const pageTitle = "Media";
    return (

        <>
        <Helmet>
        <title>{pageTitle}</title>
        
      </Helmet> 
               <div class="main-content">
            
               <Header/>
                <section className="career-wrapper">
                <Videoplayer/>

                {/* <video
        poster="assets/img/media-poster.jpg"
        src="/Video/media.mp4"
        style={videoStyle}
        autoPlay={true}
        playsinline
        preload="auto"
        muted={true}
        loop={true}
        width="100%"
        height="100%"
      /> */}
       

      

                </section>

                <div className="sec-title2 mb-55 md-mb-35 text-center">
                    <div className="sub-text" />
                    <h2 className="title mb-0"> </h2>
                </div>
                <div className="row justify-content-center" style={{ marginBottom: 80 }}>
                    <div className="col-md-8">
                        <div className="tabs-ourteam">
                            <input type="radio" name="tabs" id="tabone" defaultChecked="checked" />
                            <label htmlFor="tabone">Media Coverage</label>
                            <div className="tab">
                                <div className="tab-content" id="v-pills-tabContent" style={{ marginTop: 5 }}>
                                    <div className="tab-pane fade pt-4 me-4 active show" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                                        <div className="accordion" id="accordionPanelsStayOpenExample">
                                            <div className="container">
                                                <div className="row">
                                                    <div className="col-lg-4 col-md-6 mb-30">
                                                        <div className="project-item ">
                                                            <div className="project-img ">
                                                                <img src="assets/img/media1.jpg " alt='BlackOpal' className="gallery-img media-border" />
                                                            </div>
                                                            <div style={{ fontSize: 15, color: '#F26624', marginTop: '-10px !important', marginBottom: '8px !important' }}>News</div>
                                                            <span className="articleSummary-infoDate">
                                                                BlackOpal to fund real estate projects
                                                                through NBFC,AIF</span><br />
                                                            <span style={{ fontSize: 12, color: '#F26624' }}><a href="https://economictimes.indiatimes.com/tech/tech-bytes/blackopal-to-fund-real-estate-projects-through-nbfc-aif/articleshow/80975719.cms" target="b
                                                     " rel="noopener noreferrer">Know More</a></span>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-6 mb-30">
                                                        <div className="project-item">
                                                            <div className="project-img">
                                                                <img src="assets/img/media3.jpg" alt='Budget 2021' className="gallery-img media-border" />
                                                            </div>
                                                            <div style={{ fontSize: 15, color: '#F26624', marginTop: '-10px !important', marginBottom: '8px !important' }}>News</div>
                                                            <span className="articleSummary-infoDate">   Budget 2021 Reactions Live Updates - Prasoon Chauhan</span><br />
                                                            <span style={{ fontSize: 12, color: '#F26624' }}><a href="https://www.zeebiz.com/india/news-budget-2021-reactions-live-who-said-what-about-nirmala-sitharamans-announcements-top-quotes-from-india-inc-148147" target="_blank" rel="noopener noreferrer">Know More</a></span>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-6 md-mb-30">
                                                        <div className="project-item">
                                                            <div className="project-img">
                                                                <img src="assets/img/media4.jpg" alt='Pre-Budget Quote for Real Estate' className="gallery-img media-border" />
                                                            </div>
                                                            <div style={{ fontSize: 15, color: '#F26624', marginTop: '-10px !important', marginBottom: '8px !important' }}>News</div>
                                                            <span className="articleSummary-infoDate">  Pre-Budget Quote for Real Estate Sector - Prasoon Chauhan</span><br />
                                                            <span style={{ fontSize: 12, color: '#F26624' }}><a href="https://www.apnnews.com/pre-budget-quote-for-real-estate-sector-prasoon-chauhan-founder-ceo-blackopal/" target="_blank" rel="noopener noreferrer">Know More</a></span>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-6 sm-mb-30" style={{ marginTop: 40, marginBottom: 20 }}>
                                                        <div className="project-item">
                                                            <div className="project-img">
                                                                <img src="assets/img/media5.jpg" alt='Deduct EMI from 70% RERA Account' className="gallery-img media-border" />
                                                            </div>
                                                            <div style={{ fontSize: 15, color: '#F26624', marginTop: '-10px !important', marginBottom: '8px !important' }}>News</div>
                                                            <span className="articleSummary-infoDate">Deduct EMI from 70% RERA Account - Prasoon Chauhan </span><br />
                                                            <span style={{ fontSize: 12, color: '#F26624' }}><a href="https://realty.economictimes.indiatimes.com/news/industry/up-rera-seeks-direction-from-state-government-to-end-flats-registration-deadlock/82287952" target="_blank" rel="noopener noreferrer">Know More</a></span>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-6" style={{ marginTop: 40, marginBottom: 20 }}>
                                                        <div className="project-item">
                                                            <div className="project-img">
                                                                <img src="assets/img/media6.jpg" alt='IIFL Securities' className="gallery-img media-border" />
                                                            </div>
                                                            <div style={{ fontSize: 15, color: '#F26624', marginTop: '-10px !important', marginBottom: '8px !important' }}>News</div>
                                                            <span className="articleSummary-infoDate">   IIFL Securities - Prasoon Chauhan</span><br />
                                                            <span style={{ fontSize: 12, color: '#F26624' }}><a href="https://www.equitybulls.com/category.php?id=282178" target="_blank" rel="noopener noreferrer">Know More</a></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <input type="radio" name="tabs" id="tabtwo" />
                          {/* <label for="tabtwo">Gallery</label>
                           <div className="tab">
                                <div className="tab-content" id="v-pills-tabContent" style={{ marginTop: 5 }}>
                                    <div className="tab-pane fade pt-4 me-4 active show" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
                                        <div className="accordion" id="accordionPanelsStayOpenExample">
                                            <div className="container">
                                               <h2 class="mediah2">ddd</h2>       
                                                <div className="row">
                                                    <div className="video-container">
                                                        <video controls muted style={{ width: '100% !important' }}>
                                                            <source src="assets/img/Final.mp4" type="video/mp4" />
                                                            Your browser does not support the video tag.
                                                        </video>
                                                    </div>
                                                    <div class="col-lg-4 col-md-6 mb-30">
                                         <div class="project-item">
                                             <div class="project-img">
                                                 <img src="assets/img/event5.jpg" alt="Media" class="gallerybor"/>
                                             </div>
                                            
                                         </div>
                                     </div> 
                                     <div class="col-lg-4 col-md-6 mb-30">
                                         <div class="project-item">
                                             <div class="project-img">
                                                 <img src="assets/img/event6.jpg" alt="Media" class="gallerybor"/>
                                             </div>
                                            
                                         </div>
                                     </div>
                                                    <div class="col-lg-4 col-md-6 mb-30">
                                         <div class="project-item">
                                             <div class="project-img">
                                                 <img src="assets/img/event7.jpg" alt="Media" class="gallerybor"/>
                                             </div>
                                            
                                         </div>
                                     </div> 
                                     <div class="col-lg-4 col-md-6 md-mb-30">
                                         <div class="project-item">
                                             <div class="project-img">
                                                 <img src="assets/img/event8.jpg" alt="Media" class="gallerybor"/>
                                             </div>
                                            
                                         </div>
                                     </div>
                                                    <div class="col-lg-4 col-md-6 md-mb-30">
                                         <div class="project-item">
                                             <div class="project-img">
                                                 <img src="assets/img/event10.jpg" alt="Media" class="gallerybor"/>
                                             </div>
                                            
                                         </div>
                                     </div> 
                                                   <div class="col-lg-4 col-md-6 md-mb-30">
                                         <div class="project-item">
                                             <div class="project-img">
                                                 <img src="assets/img/event9.jpg" alt="Media" class="gallerybor"/>
                                             </div>
                                            
                                         </div>
                                     </div> 
                                                </div>
                                            </div>
                                            <div className="container">
                                              <h2 class="mediah2">ddd</h2>          
                                                <div className="row">
                                                     <div class="col-lg-4 col-md-6 mb-30">
                                         <div class="project-item">
                                             <div class="project-img">
                                                 <img src="assets/img/event11.jpg" alt="Media" class="gallerybor"/>
                                             </div>
                                            
                                         </div>
                                     </div>
                                    <div class="col-lg-4 col-md-6 mb-30">
                                         <div class="project-item">
                                             <div class="project-img">
                                                 <img src="assets/img/event12.jpg" alt="Media" class="gallerybor"/>
                                             </div>
                                            
                                         </div>
                                     </div> 
                                                    <div class="col-lg-4 col-md-6 mb-30">
                                         <div class="project-item">
                                             <div class="project-img">
                                                 <img src="assets/img/event13.jpg" alt="Media" class="gallerybor"/>
                                             </div>
                                            
                                         </div>
                                     </div>
                                     <div class="col-lg-4 col-md-6 md-mb-30">
                                         <div class="project-item">
                                             <div class="project-img">
                                                 <img src="assets/img/event14.jpg" alt="Media" class="gallerybor"/>
                                             </div>
                                            
                                         </div>
                                     </div> 
                                                     <div class="col-lg-4 col-md-6 md-mb-30">
                                         <div class="project-item">
                                             <div class="project-img">
                                                 <img src="assets/img/event15.jpg" alt="Media" class="gallerybor"/>
                                             </div>
                                            
                                         </div>
                                     </div> 
                                                    <div class="col-lg-4 col-md-6 md-mb-30">
                                         <div class="project-item">
                                             <div class="project-img">
                                                 <img src="assets/img/event16.jpg" alt="Media" class="gallerybor"/>
                                             </div>
                                            
                                         </div>
                                     </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
               <Footer/>
            </div>
 

        </>


    )
}


export default Media
