import React from 'react';

const VideoPlayer = () => {
  const videoStyle = {
    border: 'none',
    // Add any additional styles as needed
  };

  return (
    <div>
    <video
      poster="assets/img/media-poster.jpg"
      src="/Video/media.mp4"
      style={videoStyle}
      autoPlay={true}
preload={'auto'}
muted={true}
playsinline
// controls
loop={true}
   
   
    />
    <div className="overlayText">
      <p id="topText">IN NEWS</p>
    </div>
    </div>
  );
};

export default VideoPlayer;