import React from 'react'
import Content from './content' // Import the Box component

const Ouroffering = () => {

  const projectData = [
    {

      id: 1,
      title: "FINANCIAL SERVICES",
      link: "/FinancialServices",
      image:
        "assets/img/fintech.jpg",
      description: "Our tech-enabled RBI registered NBFC is committed to solving the core problem of credit availability at the grassroots level. We provide innovative financial products that are easy to access. Our retail offerings include Home Equity, Home Improvement, and Early Brokerage loans, catering to diverse needs. Additionally, we support SMEs and Corporates by offering products to bridge working capital gaps, acquisition financing, and inventory funding. We firmly believe in our bedrock belief of providing access to capital at all levels, as it is crucial for sustainable growth."
    },
    {

      id: 2,
      title: "TECHNOLOGY SERVICES",
      link: "/TechnologyServices",
      image:
        "assets/img/proptech.jpg",
      description: "Technology runs deeply enshrined across our product and service offerings. Our customer-centric real estate ecosystem leverages technology to disrupt the industry. From choosing your dream home till holding its keys, every step is meticulously designed for an unparalleled buying experience. Our products and services prioritize the customer journey, creating a harmonious and innovative approach to ensure customers are supported at every stage of their home buying journey. Embrace the future of home buying with us."
    },
    {

      id: 3,
      title: "ADVISORY SERVICES",
      image:
        "assets/img/advisory.jpg",
      link: "/AdvisoryServices",
      description: "We recognize that purchasing a home is a significant decision, and we're dedicated to making it an enriching one for you. Through transparency and seamless operations, we offer a meticulously curated journey that covers buying, selling, and financing decisions. Our holistic approach ensures that you feel confident and supported at every step of the way as you find your dream home. Discover the joy of a transparent and empowering process, where your needs are at the heart of everything we do."
    }
  ];


  return (


    <section className="services " id="offering">
      <div className="services-content">
        <div className="logo-tag"><img src="assets/img/brand-mark.png" alt='Blackopal' /></div>
      </div>
      <div className="container ">
        <div className="row">
          <div className="col-lg-6 ">
            <div className="main-title text-left">
              <h2>Our Offerings</h2>
            </div>
          </div>
        </div>
          <div className="row">
            {projectData.map((project, index) => (
              <div className="col-md-6 col-lg-4 col-xl-4">

                <Content
                  key={index}
                  title={project.title}
                  image={project.image}
                  description={project.description}
                  link={project.link}
                />

              </div>
            ))}
          </div>

        </div>




    </section>


  )
}
export default Ouroffering