import React from 'react';
import { Link } from 'react-router-dom'

const Box = ({ title, image, link, description }) => {
  return (
    <div className="row">
        <div className="col-md-6 col-lg-4 col-xl-4">
          <Link to={link}>
            <div className="for_blog feat_property animate__animated animate__fadeInUp animate__delay-2s">
              <div className="thumb overlay">
                <h3 className="info-title"> {title} </h3>
                 
                <img className="img-whp" src={image} alt="Financial Services" />
              </div>
              <div className="details">
                <div className="tc_content">
                  <p className="text-thm">
                  {description}
                  
                  </p>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>
  );
};

export default Box;
