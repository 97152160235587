import React from 'react';

import Ouroffering from '../components/Ouroffering';
import Header from '../components/Header';
import Footer from "../components/Footer";
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';



const Home = () => {


  const pageTitle = "Blackopal Group is built with the belief that happy homes form the backbone of our society";
  const metaDescription = "Blackopal Group, We strive to eliminate purchase decision anomalies, safeguard hard earned money and offer hassle-free financing options to every aspiring home buyer.";

  return (

    <div>

      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={metaDescription} />
      </Helmet>

      <div class="main-content">
        <Header />

        <section className="about2">
          <div className="container-fluid animate__animated animate__fadeInUp animate__800ms">
            <div className="row align-items-center">
              <div className="col-xl-6 col-lg-6">

                <div className="mb-4 mb-lg-0 video-wrapperr header-img  border-radius-bottom">

                  <video
                    className='react-player'
                    src='/Video/landing-banner.mp4'
                    autoPlay={true}
                    preload={'auto'}
                    muted={true}
                    // controls
                    loop={true}
                    width='100%'
                    playsinline={true}
                  />




                </div>
               
              </div>
              <div className="col-xl-6 col-lg-6 ">
                <div className="sec-title2 mt-30 home-text">
                  <div className="about-sub-text">UNLOCKING
                    HAPPINESS!</div>
                  <p className="sub-text mb-0">Blackopal Group is built with the belief that happy homes form the
                    backbone of our society. We strive to eliminate purchase decision anomalies, safeguard
                    hard earned money and offer hassle-free financing options to every aspiring home buyer.
                  </p><br />
                  <p className="sub-text mb-0">With us, you are one swipe away from finding your dream home. Your
                    happiness is our first priority.</p>
                  <div className="btn-part">
                    <Link className="readon2" to="/Aboutus"> MORE ABOUT US &gt;&gt;
                    </Link>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>


        <Ouroffering />

        <div className="rs-about  pb-100 md-pt-70 md-pb-70">
          <div className="container-fluid">
            <div className="row y-middle">
              <div className="col-lg-6 pl-220 md-pr-15 md-mb-50 animate__animated animate__fadeInLeft animate__delay-2s">
                <div className="sec-title2 mb-30 abouttop">
                  <h2 className="title mb-23">where <br />
                    ideas matter</h2>
                  <div className="about-img eco-web ">
                    <img src="assets/img/ourteam.jpg" className="borderbottom" alt="images" />
                  </div>
                  <p className="mb-0 aboutp">At Blackopal, we are a dynamic rich milieu of people from different walks
                    of life- veterans from the industry nurturing and learning from the young professionals of
                    today. We are a platform where ideas &amp; creativity are the religion and meritocracy is the
                    only guiding principle.
                  </p>
                </div>
                <div className="btn-part">
                  <Link className to="/career">MORE ON OUR CULTURE &gt;&gt; </Link>
                </div>
              </div>
              <div className="col-lg-6  animate__animated animate__fadeInRight animate__delay-3s pos">
                <div className="about-img eco-img ">
                  <img src="assets/img/ourteam.jpg" className="borderbottom" alt="images" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />

      </div>
    </div>



  )
}


export default Home;