import React, { useState } from 'react';
import Header from '../components/Header';
import Footer from "../components/Footer";
import { Helmet } from 'react-helmet';


const Contactus = () => {
  const pageTitle = "Contact Us";


  const videoStyle = {
    border: 'none', // Remove border
   
  };
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });
  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: '' });
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {};

    // Validate name
    if (formData.name.trim() === '') {
      newErrors.name = 'Name is required';
      isValid = false;
    }

    // Validate email
    if (formData.email.trim() === '') {
      newErrors.email = 'Email is required';
      isValid = false;
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(formData.email)) {
        newErrors.email = 'Invalid email format';
        isValid = false;
      }
    }

    // Validate phone
    if (formData.phone.trim() === '') {
      newErrors.phone = 'Phone is required';
      isValid = false;
    }

    // Validate message
    if (formData.message.trim() === '') {
      newErrors.message = 'Message is required';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      fetch('./contact.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      })
        .then((response) => response.json())
        .then((data) => {
          // Handle response from the PHP script
          console.log(data);
        })
        .catch((error) => {
          // Handle error
          console.error(error);
        });
    }
  };

    

  return (

  <>
    <Helmet>
        <title>{pageTitle}</title>
        
      </Helmet> 
<div class="main-content">
  
<Header/>

  <section className="career-wrapper">
  
    <video poster="assets/img/contact-poster.jpg"
          
          src= '/assets/img/contactus.mp4'
          style={videoStyle}
          autoPlay={true}
          preload={'auto'}
          muted={true}
          // controls
          loop={true}
        
        
       />
     
   

    <div className="overlayText">
      <p id="topText">LET’S WORK TOGETHER</p>
    </div>
  </section>

  <div className="bo-contact contact-style2  pb-100 pt-10">
    <div className="container">
      <div className="row">
        <div className="col-lg-6 pr-40 md-pr-15 md-mb-50">
          <div className="contact-map">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3506.038170625979!2d77.38488321507974!3d28.50849928246692!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce95e5d85ce4b%3A0xaa805db076718b79!2sBlackopal%20Group!5e0!3m2!1sen!2sin!4v1671191061068!5m2!1sen!2sin" width={600} style={{border: 0}} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
          </div>
        </div>
        <div className="col-lg-6 form-bg">
          <div className="sec-title2 mb-45 md-mb-25">
            <h2 className="title">
              Contact Us
            </h2>
          </div>
          <div className="contact-wrap">
            
          {isSubmitted ? (
        <div className="success-message">Form submitted successfully!</div>
      ) : (
             
          
                    <form onSubmit={handleSubmit} action="/contact.php" method="post">
          <div className="row" style={{marginBottom: 20}}>
            <div className="col-md-6">
              <div className="form-input">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  placeholder="Enter your name"
                  value={formData.name}
                  onChange={handleChange}
                  
                />
                
              </div>
              {errors.name && <div className="error-message">{errors.name}</div>}
            </div>
           
            <div className="col-md-6">
              <div className="form-input">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  placeholder="Enter your email"
                  value={formData.email}
                  onChange={handleChange}
                  
                />
                  {errors.email && <div className="error-message">{errors.email}</div>} 
            </div>
          
              </div>
             
          </div>
          <div className="row" style={{marginBottom: 20}}>
            <div className="col-md-6">
              <div className="form-input">
                <label htmlFor="phone">Phone</label>
                <input
                  type="number"
                  className="form-control"
                  name="phone"
                  placeholder="Enter phone"
                  value={formData.phone}
                  onChange={handleChange}
                  
                />
               {errors.phone && <div className="error-message">{errors.phone}</div>}
              </div>
            
            </div>
           
            <div className="col-md-6">
              <div className="form-input">
                <label htmlFor="message">Message</label>
                <textarea
                  className="form-control"
                  name="message"
                  placeholder="Type your message here"
                  value={formData.message}
                  onChange={handleChange}
                  
                />
                  {errors.message && <div className="error-message">{errors.message}</div>}
              </div>
            
            </div>
         
          </div>
          <input type="submit" name="submit" className="readon submit" defaultValue="Submit" />
        </form>
      )}
          </div>
        </div>
      </div>
    </div>
    {/* Contact Icon Section Start */}
    <div className="bo-contact main-home">
      <div className="container">
        <div className="contact-icons-style box-address pt-5012 md-pt-70">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-6 md-mb-30">
              <div className="contact-item">
                <div className="contact-icon">
                  <img src="assets/img/map-ico.png" alt="images" />
                </div>
                <div className="content-text">
                  <h2 className="title">Noida office</h2>
                  <p className="services-txt">11th Floor, Gulshan One29,
                    Sec-129 Noida,<br />
                    Uttar Pradesh- 201304<br />
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 xs-mb-30">
              <div className="contact-item">
                <div className="contact-icon">
                  <img src="assets/img/map-ico-2.png" alt="images" />
                </div>
                <div className="content-text">
                  <h2 className="title">Gurugram Office</h2>
                  <p className="services-txt">10C, Tower-B3<br />Spaze IT Park, Sector-49<br />Gurugram-122018</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 xs-mb-30">
              <div className="contact-item">
                <div className="contact-icon">
                  <img src="assets/img/email-ico.png" alt="images" />
                </div>
                <div className="content-text">
                  <h2 className="title">Email us</h2>
                  <a href="mailto:info@blackopalgroup.in" className="services-txt">info@blackopalgroup.in</a><br />
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="contact-item">
                <div className="contact-icon">
                  <img src="assets/img/phone-ico.png" alt="images" />
                </div>
                <div className="content-text">
                  <h2 className="title">Call us</h2>
                  <p className="services-txt"> 0120-5109230</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
<Footer/>

</div>

  </>

  )
}


export default Contactus
