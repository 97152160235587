import React from 'react'
import Header from '../components/Header';
import Footer from "../components/Footer";
import { Helmet } from 'react-helmet';

 const AdvisoryServices = () => {
  const pageTitle = "Advisory Services";
  const videoStyle = {
    border: 'none', // Remove border
   
  };

  return (

   
    <>
    <Helmet>
        <title>{pageTitle}</title>
        
      </Helmet> 
<div class="main-content">
<Header/>
<div>
  <section className="about">
    <div className="container-fluid animate__animated animate__fadeInUp animate__800ms">
      <div className="row align-items-center">
        <div className="col-xl-6 col-lg-6">
          <div className="mb-4 mb-lg-0 video-wrapper header-img  border-radius-bottom">


            <video poster="assets/img/advisory-poster.jpg"
            className='react-player'
             src= '/Video/advisory.mp4'
             style={videoStyle}
             autoPlay={true}
             playsinline
          preload={'auto'}
          muted={true}
          // controls
          loop={true}
             width='100%'
            height='100%'
            type="video/mp4"
          />
    
          
          </div>
        </div>
        <div className="col-xl-6 col-lg-6 ">
          <div className="sec-title2 mt-30 about-text">
            <div className="about-sub-text">
              <div className="technology" style={{marginBottom: 20}}>
                <h2>Deepening Customer<br /> Engagement </h2>
               
              </div>
              <p className="ad">With a profound understanding of project and asset management, we provide an all-encompassing suite of services for real estate projects at every stage of their lifecycle. Our expertise lies in financial project management, sales &amp; marketing, and CRM management. Supported by robust processes and delivery KRAs, we offer optimal project solutions. Experience our comprehensive services, tailored to drive success and maximize the potential of your real estate investments.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section className="services" id="offering" style={{background: '#fbfbfb'}}>
    <div className="container">
      <div className="row">
        <div className="col-lg-6 ">
          <div className="main-title text-left">
            <h2 style={{color: '#f26624'}}>OUR OFFERINGS</h2>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 col-lg-4 col-xl-4">
          <div className="for_blog feat_property animate__animated animate__fadeInUp animate__delay-2s">
            <div className="thumb2 overlay borderhome">
              <h3 className="info-title h8">
                Asset
                Management                     </h3>
              <img className="img-whp" src="assets/img/const.jpg" />
            </div>
            <div className="details">
              <div className="tc_content">
                <p className="text-thm">
                  With a niche asset management team, we possess a distinctive ability to oversee the entire project life cycle, whether as a development partner or through equity participation. Additionally, we specialize in crafting tailored solutions to address specific challenges in Sales &amp; Marketing, CRM support, and the project's capital structure. Our expertise ensures comprehensive management and innovative problem-solving for optimal project success.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-4 col-xl-4">
          <div className="for_blog feat_property animate__animated animate__fadeInUp animate__delay-2s">
            <div className="thumb2 overlay borderhome">
              <h3 className="info-title h8">Strategic Consulting</h3>
              <img className="img-whp" src="assets/img/asset.jpg" />
            </div>
            <div className="details">
              <div className="tc_content">
                <p className="text-thm">
                  We differentiate ourselves through data-driven consulting services, seamlessly integrating them into all projects for optimized outcomes. Our specialization lies in designing marketing strategies, including price discovery and product &amp; sales strategy. With a combined 70 years of experience in real estate development, financing, and the IPC industry, we have carved out a niche by crafting unique strategies for each project.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-4 col-xl-4">
          <div className="for_blog feat_property animate__animated animate__fadeInUp animate__delay-2s">
            <div className="thumb2 overlay borderhome">
              <h3 className="info-title h8">Retail Advisory</h3>
              <img className="img-whp" src="assets/img/retail.jpg" />
            </div>
            <div className="details">
              <div className="tc_content">
                <p className="text-thm">From our retail advisory services, clients can expect a seamless and efficient end-to-end experience, customized to their specific needs. Our tech-enabled solutions encompass price discovery, price negotiation, financing, and comprehensive transaction management. As a one-stop shop in the real estate industry, we provide curated and personalized support to ensure optimal outcomes for every buyer.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div></section>
  <div className="rs-about  pb-100 md-pt-70 md-pb-70">
    <div className="container-fluid">
      <div className="row y-middle">
        <div className="col-lg-6 pl-220 md-pr-15 md-mb-50 animate__animated animate__fadeInLeft animate__delay-2s">
          <div className="sec-title2 mb-30 abouttop">
            <h2 className="title mb-23">Wealth Management</h2>
            <div className="about-img eco-web">
              <img src="assets/img/wealth.png" alt="images" />
            </div>
            <p className="mb-0 aboutp">Our wealth management services provide personalized strategies to grow and protect your wealth. Our team analyzes your financial goals and risk tolerance to create customized investment portfolios. With comprehensive financial planning, tax optimization, and asset allocation, we help you navigate the complex world of finance and achieve long-term financial success.
            </p>
          </div>
        </div>
        <div className="col-lg-6  animate__animated animate__fadeInRight animate__delay-3s pos">
          <div className="about-img eco-img">
            <img src="assets/img/wealth.png" alt="images" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

   


    <Footer/>
</div>

    </>


  )
}

export default AdvisoryServices